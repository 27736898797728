<template>
<el-dialog :visible.sync="isVisible" :align-center="true">
  <div class="info-dialog">
    <div class="info-dialog-title">{{title}}</div>
    <div v-if="subtitle" class="info-dialog-subtitle">{{subtitle}}</div>
    <el-button @click="isVisible = false" style="margin: 36px auto 0;">Понятно</el-button>
  </div>
</el-dialog>
</template>

<script>
export default {
  name: "InfoDialog",
  data() {
    return {
      isVisible: false,
      title: '',
      subtitle: '',
    }
  },
  methods: {
    open(params) {
      this.title = '';
      this.subtitle = '';
      this.isVisible = true;
      this.title = params.title;
      this.subtitle = params.subtitle;
    },
  }
}
</script>

<style scoped lang="scss">
.info-dialog {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.info-dialog-title {
  font-size: 20px;
  text-align: center;
}

.info-dialog-subtitle {
  font-size: 14px;
  margin-top: 8px;
  text-align: center;
}
</style>