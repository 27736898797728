import {fabric} from "fabric";
import store from "@/store";
import geometryFunctions from "@/pages/project/canvas_methods/algorithms/geometryFunctions";
import HMElement from "@/pages/project/canvas_methods/elements/HMElement";

class Polygon extends HMElement {
  constructor(hmCanvas, data = {}, options = {}) {
    super(hmCanvas, data, options);
    this.strokeWidth = 1.1 * (hmCanvas.project.scale / 100);
  }

  addPoint(point, findIndex = false) {
    if (findIndex && this.fabricElement.points) {
      let index = this._findIndexForPoint(point, this.fabricElement.points);
      this.data.pointsCanvas.splice(index, 0, point);
    } else {
      this.data.pointsCanvas.push(point);
    }
    this.render(true).update();
    this.select();
    this.hmCanvas.fabricCanvas.requestRenderAll();
  }

  removePoint(point) {
    if (this.fabricElement.points) {
      this.data.pointsCanvas.splice(this._findClosestPoint(point, this.fabricElement.points), 1);
      this.render(true).update();
    } else {
      this.remove();
    }
  }

  createElement() {
    let element = null;
    if (this.data.pointsCanvas.length > 1) {
      element = new fabric.Polygon(this.data.pointsCanvas, {
        fill: (this.data.color || store.state.polygonSettings.colors.basic) + '66',
        // stroke: 'red',
        stroke: this.data.color || store.state.polygonSettings.colors.basic,
        strokeWidth: this.strokeWidth,
        hasControls: true,
        hoverCursor: 'pointer',
        hasBorders: false,
        objectCaching: false,
        lockMovementX: true,
        lockMovementY: true,
        perPixelTargetFind: true,
        transparentCorners: false,
      });
      // this.fabricElement.on('deselected', this.options.onDeselect);
      var lastControl = element.points.length - 1;
      element.cornerStyle = 'circle';
      element.cornerColor = 'red'
      element.cornerStrokeColor = 'red'
      element.controls = element.points.reduce((acc, point, index) => {
        acc['p' + index] = new fabric.Control({
          positionHandler: (dim, finalMatrix, fabricObject) => this._polygonPositionHandler(dim, finalMatrix, fabricObject, index),
          actionHandler: this._anchorWrapper(index > 0 ? index - 1 : lastControl, (...args) => this._actionHandler(...args)),
          actionName: 'modifyPolygon',
          pointIndex: index,
          hoverCursor: 'pointer',
        });
        return acc;
      }, {});
    } else if (this.data.pointsCanvas.length === 1) {
      element = new fabric.Circle({
        radius: 1,
        top: this.data.pointsCanvas[0].y,
        left: this.data.pointsCanvas[0].x,
        fill: store.state.polygonSettings.colors.basic + '66',
        stroke: store.state.polygonSettings.colors.basic + '66',
        strokeWidth: 3,
        originX: 'center',
        originY: 'center',
        hasControls: false,
      });
    }
    return element;
  }

  _findIndexForPoint(point, points) {
    let distances = points.map((p, i) => ({
      d: geometryFunctions.pDistance(point, p, points[(i + 1) % points.length]),
      i,
      p1: point,
      p2: points[(i + 1) % points.length]
    }));
    distances = distances.sort((a, b) => a.d - b.d);
    return distances[0].i + 1;
  }

  _findClosestPoint(point, points) {
    let distances = points.map((p, i) => ({d: geometryFunctions.distance(point, p), i}));
    distances = distances.sort((a, b) => a.d - b.d);
    return distances[0].i;
  }

  _polygonPositionHandler(dim, finalMatrix, fabricObject, index) {
    var x = (fabricObject.points[index].x - fabricObject.pathOffset.x),
      y = (fabricObject.points[index].y - fabricObject.pathOffset.y);
    if (!fabricObject.canvas) return false;
    return fabric.util.transformPoint(
      {x: x, y: y},
      fabric.util.multiplyTransformMatrices(
        fabricObject.canvas.viewportTransform,
        fabricObject.calcTransformMatrix()
      )
    );
  }

  _actionHandler(eventData, transform, x, y) {
    var polygon = transform.target,
      currentControl = polygon.controls[polygon.__corner],
      mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center'),
      polygonBaseSize = Polygon._getObjectSizeWithStroke(polygon),
      size = polygon._getTransformedDimensions(0, 0);
    polygon.points[currentControl.pointIndex] = {
      x: mouseLocalPosition.x * polygonBaseSize.x / size.x + polygon.pathOffset.x,
      y: mouseLocalPosition.y * polygonBaseSize.y / size.y + polygon.pathOffset.y
    };
    this.onCornersMove();
    return true;
  }

  onCornersMove() {
  }

  _anchorWrapper(anchorIndex, fn) {
    return (eventData, transform, x, y) => {
      var fabricObject = transform.target,
        absolutePoint = fabric.util.transformPoint({
          x: (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x),
          y: (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y),
        }, fabricObject.calcTransformMatrix()),
        actionPerformed = fn(eventData, transform, x, y),
        newDim = fabricObject._setPositionDimensions({}),
        polygonBaseSize = Polygon._getObjectSizeWithStroke(fabricObject),
        newX = (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) / polygonBaseSize.x,
        newY = (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) / polygonBaseSize.y;
      fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);
      return actionPerformed;
    }
  }

  static _getObjectSizeWithStroke(object) {
    var stroke = new fabric.Point(
      object.strokeUniform ? 1 / object.scaleX : 1,
      object.strokeUniform ? 1 / object.scaleY : 1
    ).multiply(object.strokeWidth);
    return new fabric.Point(object.width + stroke.x, object.height + stroke.y);
  }
}

export default Polygon;
