import Api from "@/api";
import globalMixins from "@/globalMixins";

class RequestsQueue {
  constructor(hmCanvas, onUpdate) {
    this.elements = [];
    this.hmCanvas = hmCanvas;
    this.$api = new Api();
    this.debounceRequest = globalMixins.debounce(() => this.request(), 200);
    this.onUpdate = onUpdate;
  }

  add(element) {
    let index = this.elements.findIndex(el => element.id && el.id == element.id || el._id == element._id);
    if (element.type !== 'door' || element.title) {
      if (index >= 0) this.elements[index] = element;
      else this.elements.push(element);
    }
    this.onUpdate(element);
    this.debounceRequest(this.elements);
  }

  request(elements = null) {
    if (!elements) {
      elements = this.elements
      this.elements = [];
    }
    this.$api.request('element/set2', {elements}).then(data => {
      if (data && data.response) this.hideError();
      else this.showError();
      try {
        for (let _id in data.response) {
          this.hmCanvas.element(_id).data.id = data.response[_id];
        }
        this.hmCanvas.options.onElementsChanged();
      } catch (e) {
        console.log('then catch')
      }
    }).catch(() => {
      console.log('catch', elements);
      this.showError();
      setTimeout(() => {
        this.request(elements);
      }, 1000);
    });
  }

  showError() {

  }

  hideError() {

  }
}

export default RequestsQueue;
