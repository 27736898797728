import {fabric} from "fabric";
import HMElement from "@/pages/project/canvas_methods/elements/HMElement";
import store from "@/store";
import globalMixins from "@/globalMixins";

class Sign extends HMElement {
  constructor(hmCanvas, data, options) {
    super(hmCanvas, data, options);
    this.rotatable = true;
    this.removable = false;
  }

  createElement() {
    let fontSize = 6 * (this.hmCanvas.project.scale / 100);
    let text = [this.data.sign, this.hmCanvas.plan.title, this.data.signNumber].join('_')
    console.log('createElement sign',globalMixins.clone(this.data));
    return new fabric.Text(text, {
      fontSize,
      fontFamily: 'ABCFavoritPro',
      hasControls: false,
      fontWeight: 400,
      fill: '#ffffff',
      backgroundColor: store.state.userData.signs[this.data.sign].color || 'red',
      splitByGrapheme: false,
      originX: 'center',
      originY: 'center',
      top: this.data.pointCanvas.y,
      left: this.data.pointCanvas.x,
      angle: this.data.angle,
    });
  }

  placeCorrectly() {
    let picta = this.hmCanvas.elements.find(el => el.data._id == this.data.arrangement_id);
    let pictaAngle = parseInt(picta.fabricElement.angle) || 0;
    let elementsToIntersect = this.hmCanvas.elements.filter(el => ['sign', 'arrangement'].includes(el.data.type));
    let startAngle = this._pictaAngle(pictaAngle) + 90;
    for (let shiftIndex = 0; shiftIndex < 2; ++shiftIndex) {
      for (let shiftSign = 1; shiftSign >= -1; shiftSign -= 2) {
        for (let angleIndex = 0; angleIndex < 4; ++angleIndex) {
          let angle = (startAngle + angleIndex * 90).mod(360);
          let shift = this.fabricElement.height * shiftSign * shiftIndex;
          this._place(
            {x: picta.fabricElement.left, y: picta.fabricElement.top},
            this.fabricElement,
            angle,
            shift,
            this.scalable(7) + this.scalable(5) * Math.abs(Math.cos((pictaAngle - angle) / 180 * Math.PI)),
            // this.scalable(8) + this.scalable(4),
          );
          let intersects = false;
          elementsToIntersect.forEach(el => {
            if (this.fabricElement.intersectsWithObject(el.fabricElement)) {
              intersects = true;
              return false;
            }
          })
          if (!intersects) return this;
        }
      }
    }
    return this;
  }

  _pictaAngle(angle) {
    if (!angle || (angle >= 330 || angle < 30)) return 0;
    else if (angle >= 30 && angle < 150) return 90
    else if (angle >= 150 && angle < 211) return 180
    else if (angle >= 211 && angle < 330) return 270
  }

  _place(point, element, angle, shift, margin) {
    // margin = this.scalable(8);
    let params = {};
    if (angle === 0) {
      params.angle = 0;
      params.pointCanvas = {
        x: point.x + this.fabricElement.width / 2 + margin,
        y: point.y + shift
      };
    } else if (angle === 90) {
      params.angle = 270;
      params.pointCanvas = {
        x: point.x - shift,
        y: point.y + element.width / 2 + margin
      };
    } else if (angle === 180) {
      params.angle = 0;
      params.pointCanvas = {
        x: point.x - element.width / 2 - margin,
        y: point.y - shift
      };
    } else if (angle === 270) {
      params.angle = 270;
      params.pointCanvas = {
        x: point.x + shift,
        y: point.y - element.width / 2 - margin
      };
    }
    for (let i in params) this.data[i] = params[i];
    this.render();
  }
}

export default Sign;
