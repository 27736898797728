import Vue from "vue";
import Router from 'vue-router';
import Homepage from "@/pages/homepage/Homepage";
import ProjectView from "@/pages/project/ProjectView";
import ProjectPage from "@/pages/project/ProjectPage";
import ProjectDesignPage from "@/pages/project/ProjectDesignPage";
import Settings from "@/pages/Settings";

Vue.use(Router);

function configRoutes() {
    return [
        {
            path: '/',
            component: Homepage,
            children: [
                {
                    path: '',
                    component: Homepage,
                    name: 'Homepage',
                },
            ]
        },
        {
            path: '/settings',
            component: Settings,
            name: 'Settings'
        },
        {
            path: '/project',
            component: ProjectView,
            children: [
                {
                    path: ':id',
                    name: 'ProjectPage',
                    component: ProjectPage
                },
                {
                    path: 'design/:id/:plan_id/:elementType?',
                    name: 'ProjectDesignPage',
                    component: ProjectDesignPage,
                }
            ]
        }
    ];
}

export const router = new Router({
    mode: 'history',
    linkActiveClass: 'active',
    routes: configRoutes(),
});
