<template>
  <el-dialog :visible.sync="isVisible" title="Новый проект">
    <el-form ref="form" :model="project" label-position="top" :rules="rules">
      <el-form-item required label="Название проекта" prop="title" :error="errorTitle">
        <el-input v-model="project.title" placeholder="" @input="errorTitle = ''; onProjectTitleInput()"/>
      </el-form-item>
      <div class="form-actions">
        <el-button @click="sendForm" type="primary" :loading="loading" style="width: 120px"
        :disabled="isDisabled">
          {{ loading ? '' : 'Сохранить' }}
        </el-button>
        <el-button @click="isVisible=false" :disabled="loading">Отмена</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import draggable from 'vuedraggable';
import projectMixins from "@/mixins/projectMixins";
import globalMixins from "@/globalMixins";

export default {
  name: "AddProjectDialog",
  components: {
    draggable,
  },
  mixins: [projectMixins],
  emits: ['on-create'],
  data() {
    return {
      isVisible: false,
      project: {},
      loading: false,
      countFilesLoading: 0,
      filesLoading: false,
      rules: {
        title: [
          {required: true, message: 'Укажите название', trigger: 'change'},
          {required: true, message: 'Укажите название', trigger: 'blur'},
        ]
      },
      errorTitle: ''
    }
  },
  methods: {
    open() {
      this.project = {plans: [], eng: 0, numeration_type: 3, zones: [1, 2, 3], title: ''};
      this.isVisible = true;
      setTimeout(()=>{
        this.$refs.form.resetFields();
      });
    },
    close() {
      this.isVisible = false;
    },
    onProjectTitleInput() {
      const debouncedInput = globalMixins.debounce(()=>{
        this.checkProjectName(this.project).then(data=>{
          if (!data.response) {
            this.errorTitle = 'Проект с таким названием уже есть';
          } else this.errorTitle = '';
        })
      }, 300);
      debouncedInput();
    },
    sendForm() {
      this.$refs.form.validate(isValid => {
        if (isValid) {
          this.loading = true;
          this.checkProjectName(this.project).then((data) => {
            if (data.response) {
              this.errorTitle = '';
              this.$api.request('project/create', this.project).then((data) => {
                this.loading = false;
                this.isVisible = false;
                this.$router.push(`/project/${data.response.id}`);
                // this.$emit('on-create');
                // this.$store.state.eventBus.$emit('on-create-project');
              }).catch(() => {
                this.loading = false;
              })
            } else {
              this.errorTitle = 'Проект с таким названием уже есть';
              this.loading = false;
            }
          }).catch(()=>{
            this.loading = false;
          })
        }
      })
    }
  },
  computed: {
    isDisabled() {
      return !this.project.title || this.errorTitle !== ''
    }
  }
}
</script>

<style scoped lang="scss">

.plans-list {
  margin-bottom: 32px;
}

.plans-list-item {
  margin-bottom: 8px;
  display: grid;
  grid-template-columns: 160px 1fr auto;
  grid-column-gap: 8px;
  align-items: center;

  &-filename {
    display: flex;
    align-items: center;
    cursor: grab;

    &__text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      direction: rtl;
    }

    .drag-icon {
      margin-right: 4px;
      min-width: 16px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.plans-empty-list {
  width: 100%;
  padding: 32px 0;
  text-align: center;
}
</style>
