<template>
  <div class="page projects-page">
    <LoadingBlock v-if="loading"/>
    <AuthErrorBlock v-else-if="authError"/>
    <el-table v-else :data="searchedProjects" @cell-click="onRowClick"
              :empty-text="projects.length ? 'Проект не найден' : 'Проектов нет'">
      <el-table-column label="Название проекта" prop="title">
        <template #default="scope">
          <div class="project-title">{{ scope.row.title }}</div>
        </template>
      </el-table-column>
      <el-table-column width="40px">
        <template #default="scope">
          <i @click="openRemoveProjectDialog(scope.row.id)" class="el-icon el-icon-close remove-project-button"></i>
        </template>
      </el-table-column>
    </el-table>
    <ConfirmDialog ref="confirm_remove_project_dialog" title="Вы уверены, что хотите удалить проект?"
                   confirm-button="Да, удалить" @confirm="removeProject"/>
  </div>
</template>

<script>
import ConfirmDialog from "@/dialogs/ConfirmDialog";
import AuthErrorBlock from "@/components/AuthErrorBlock";
import LoadingBlock from "@/components/LoadingBlock";

export default {
  name: "Homepage",
  components: {LoadingBlock, AuthErrorBlock, ConfirmDialog},
  data() {
    return {
      projects: [],
      searchedProjects: [],
      authError: false,
      loading: false,
    }
  },
  methods: {
    getProjects() {
      this.loading = true;
      this.$api.request('project/get').then((data) => {
        this.loading = false;
        this.authError = false;
        this.projects = data.response;
        this.searchedProjects = data.response;
      }).catch((data) => {
        this.loading = false;
        console.log('catch', data, data.error?.type, data.error?.type === 'auth');
        this.authError = data.error?.type === 'auth';
      })
    },
    onRowClick(item, cellProps) {
      if (cellProps.property === 'title')
        this.$router.push({name: 'ProjectPage', params: {id: item.id}});
    },
    openRemoveProjectDialog(id) {
      this.$refs.confirm_remove_project_dialog.open([id]);
    },
    removeProject(ids) {
      this.$api.request(`project/delete/${ids[0]}`).then(() => {
        this.getProjects();
        this.$refs.confirm_remove_project_dialog.close();
      })
    },
  },
  mounted() {
    this.getProjects();
  },
  created() {
    this.$store.state.eventBus.$on('on-create-project', () => {
      this.getProjects();
    });
    this.$store.state.eventBus.$on('on-search-project', q=>{
      this.searchedProjects = this.projects.filter(p=>p.title.toLowerCase().includes(q.toLowerCase()))
    });
  },
  beforeDestroy() {
    this.$store.state.eventBus.$off('on-create-project');
    this.$store.state.eventBus.$off('on-search-project');
  },
  // watch: {
  //   '$store.state.userData': function (userData) {
  //     if (userData?.user.id && this.authError) this.getProjects();
  //     else this.authError = true;
  //   }
  // }
}
</script>

<style scoped lang="scss">
.project-title {
  font-size: 24px;
  color: var(--primary-color);
  font-weight: 500;
}

.remove-project-button {
  font-size: 24px;
  color: var(--primary-color);
  cursor: pointer;
}
</style>
