import {fabric} from "fabric";
import Polygon from "@/pages/project/canvas_methods/elements/polygon";
import Polyline from "@/pages/project/canvas_methods/elements/polyline";
import Pictogram from "@/pages/project/canvas_methods/elements/pictogram";
import Sign from "@/pages/project/canvas_methods/elements/sign";
import Point from "@/pages/project/canvas_methods/elements/point";
import RequestsQueue from "@/pages/project/canvas_methods/requestsQueue";
import store from "@/store";
import geometryFunctions from "@/pages/project/canvas_methods/algorithms/geometryFunctions";
import globalMixins from "@/globalMixins";
import HMElement from "@/pages/project/canvas_methods/elements/HMElement";

class HMCanvas {
  constructor(canvasElement, options = {}) {
    fabric.Group.prototype.controls = false;
    // fabric.Group.prototype.lockMovementY = true;
    // fabric.Group.prototype.lockMovementX = true;
    this.saveSVG = globalMixins.debounce(() => this.requestsQueue.$api.request('plan/update/' + this.plan.id, {svg: this.fabricCanvas.toSVG()}).then(() => {
      this.options.onSvgSaved();
    }), 300);
    this.fabricCanvas = new fabric.Canvas(canvasElement, {
      willReadFrequently: true,
    });
    // this.canvasWidth = canvasElement.width;
    // this.canvasHeight = canvasElement.height;
    this.requestsQueue = new RequestsQueue(this, element => {
      options.onUpdateElement(element);
      if (store.state.pointTypes.includes(element.type) && store.state.arrangementTabs.includes(this.tabInfo.key)) {
        this.genPictograms(this.elements, () => {
          if (this.tabInfo.key === 'sign') setTimeout(() => this.genSigns(this.elements), 100);
        });
      }
      // if (this.tabInfo && this.tabInfo.key === 'sign') this.saveSVG();
      // if (store.state.pointTypes.includes(element.type)) this.updatePointNumbers();
    });
    this.options = options
    this.state = {};
    this.controlsState = {};
    this.events();
    window.hmCanvas = this;
  }

  toggleMode(mode, forceSet) {
    if (this.state.mode === 'create') this.creatingElement = null;
    if (forceSet && this.state.mode === mode) return;
    this.state.mode = this.state.mode === mode ? '' : mode;
    this.options.onModeChanged(this.state.mode);
  }

  setFilters(filters) {
    this.elements.forEach(el => {
      if (store.state.arrangementTabs.includes(el.data.type)) {
        el.show(
          !filters.hideSigns[el.data.sign] &&
          (!filters.door_id || filters.door_id == el.data.door_id) /*&&*/
          // (!filters.search || el.data.sign.indexOf(filters.search) > -1)
        );
      }
    });
  }

  searchElement(project_id, sign, type, callback) {
    this.requestsQueue.$api.request(`element/find/${project_id}`,
      {sign, type}).then(data => {
      if (callback) callback(data.response);
    });

  }

  genSigns(elements) {
    console.log('genSigns');
    let hmElement = new HMElement(this);
    elements.forEach(arrangement => {
      console.log(globalMixins.clone(arrangement.data));
      if (arrangement.data.type !== 'arrangement') return;
      let pointCanvas = hmElement._percentToCoords([arrangement.data.point])[0];
      pointCanvas.x += 15 * this.project.scale / 100;
      if (!elements.find(el => el.data.arrangement_id == arrangement.data._id && el.data.type === 'sign' && el.data.sign === arrangement.data.sign)) {
        this.elements.push(this._createElement({
          door_id: arrangement.data.door_id,
          arrangement_id: arrangement.data._id,
          point: hmElement._coordsToPercent([pointCanvas])[0],
          sign: arrangement.data.sign,
          type: 'sign',
        }).render(true).placeCorrectly().render().update());
      }
    });
    this.updateSignNumbers();
    this.saveSVG();
  }

  updateSignNumbers() {
    let elements = this.elements.filter(el => el.data.type === 'sign').sort((a, b) => {
      if (a.fabricElement.left != b.fabricElement.left) return a.fabricElement.left - b.fabricElement.left;
      else return b.fabricElement.top - a.fabricElement.top;
    })
    let signNumbers = {};
    elements.forEach(el => {
      let signNumber = (signNumbers[el.data.sign] || 1);
      signNumbers[el.data.sign] = signNumber + 1;
      el.update({signNumber}).render();
    })
  }

  removeUnusedSignsAndArrangement() {
    let points = this.elements.filter(el => store.state.pointTypes.includes(el.data.type));
    points.forEach(point => {
      let pointSigns = point.data.signs;
      let pointId = point.data.id;
      for (let i = 0; i < this.elements.length; i++) {
        let element = this.elements[i];
        if (element.data.door_id === pointId && !pointSigns.includes(element.data.sign)) {
          element.remove(true);
          i--;
        }
      }
    });
  }

  genPictograms(elements, onRendered) {
    setTimeout(() => {
      this.removeUnusedSignsAndArrangement();
    }, 300)
    let hmElement = new HMElement(this);
    let points = elements.filter(el => store.state.pointTypes.includes(el.data.type));
    let renderedSome = false;
    points.forEach((point, i) => {
      // if (door.type !== 'door') return;
      let pointCanvas = hmElement._percentToCoords([point.data.point])[0];
      console.log('points.forEach', {i}, points.length, globalMixins.clone(point.data.signs));
      if (point.data.signs) {
        let signs = point.data.signs.filter(sign => !elements.find(el => el.data.door_id == point.data.id && el.data.type === 'arrangement' && el.data.sign === sign));
        console.log({signs})
        signs.forEach((sign, j) => {
          let picta = this._createElement({
            door_id: point.data.id,
            point: hmElement._coordsToPercent([{
              x: pointCanvas.x,
              y: 7 * (point.data.signs.indexOf(sign)) * this.project.scale / 100 + pointCanvas.y
            }])[0],
            sign,
            type: 'arrangement'
          });
          console.log('signs.forEach', {i, j}, points.length, signs.length);
          picta.render(true, (points.length - 1 === i && signs.length - 1 === j && (renderedSome = true)) ? onRendered : () => picta.rotate(0)).update();/*globalMixins.rand(0, 360)*/
          this.elements.push(picta);
          // renderedSome = true;
          // setTimeout(() => picta.rotate(globalMixins.rand(0, 360)), 1000);
        })
      }
    });
    if (!renderedSome && onRendered) onRendered(this.elements);
  }

  setPlan(plan, project, tabInfo = {}, onLoadedCallback) {
    fabric.Group.prototype.lockMovementX = !['door', 'not_room', 'connection', 'arrangement', 'sign'].includes(tabInfo.key);
    fabric.Group.prototype.lockMovementY = !['door', 'not_room', 'connection', 'arrangement', 'sign'].includes(tabInfo.key);
    this.tabInfo = tabInfo;
    this.plan = globalMixins.clone(plan);
    store.state.plan = this.plan;
    this.elements = [];
    this.project = project;
    this.fabricCanvas.clear();
    fabric.Image.fromURL(this.plan.file, async (img) => {
      let scale = Math.min(this.fabricCanvas.width / img.width, this.fabricCanvas.height / img.height);
      this.imageWidth = img.width * scale
      this.imageHeight = img.height * scale
      this.fabricCanvas.setBackgroundImage(img, this.fabricCanvas.renderAll.bind(this.fabricCanvas), {
        scaleX: scale, scaleY: scale,
      });
      if (this.fabricCanvas.getZoom() === 1) {
        let vpt = this.fabricCanvas.viewportTransform;
        vpt[4] = (this.fabricCanvas.width - this.imageWidth) / 2;
        vpt[5] = (this.fabricCanvas.height - this.imageHeight) / 2;
        this.fabricCanvas.requestRenderAll();
      }
      for (let i in this.plan.elements) {
        let element = this.plan.elements[i];
        let el = this._createElement(element, this)
        if (!tabInfo.onlyRender || tabInfo.onlyRender.includes(element.type)) {
          await el.waitToRender(true);
        }
        this.elements.push(el)
      }
      // this.plan.elements
      //   .forEach(element => {
      //
      //   });
      if (['door', 'not_room', 'polyline'].includes(tabInfo.key)) this.updatePointNumbers();
      else if (tabInfo.key === 'arrangement') this.genPictograms(this.elements);
      else if (tabInfo.key === 'sign') {
        this.genPictograms(this.elements, () => setTimeout(() => this.genSigns(this.elements), 300));
      }
      if (onLoadedCallback) onLoadedCallback();
      this.options.onLoaded();
    })
  }

  _createElement(data) {
    if (data.type === 'polygon') return new Polygon(this, data);
    else if (data.type === 'polyline') return new Polyline(this, data);
    else if (data.type === 'arrangement') return new Pictogram(this, data);
    else if (data.type === 'sign') return new Sign(this, data);
    else if (store.state.pointTypes.includes(data.type)) return new Point(this, data, {
      evented: this.tabInfo.key !== 'polyline', showText: data.type === 'connection' || this.tabInfo.key === 'polyline',
    });
    else throw `Неизвестный тип элемента: ${data.type}}`;
  }

  genNumber(data) {
    if (!data.number) return '';
    if (+this.project.numeration_type === 1 && +this.plan.floor > 0) return this.plan.floor * 100 + data.number;
    else if (+this.project.numeration_type === 2) return data.number + this.plan.numerationInfo.total;
    else return '';
  }

  reset() {
    if (this.fabricCanvas) {
      this.fabricCanvas.clear();
      this.fabricCanvas.dispose();
      this.fabricCanvas = null;
      document.removeEventListener('keydown', this._keyDown);
      document.removeEventListener('keyup', this._keyUp);
      document.removeEventListener('keypress', this._keyPress);
    }
  }

  element(id) {
    return this.elements.find(el => el.data[String(id).substring(0, 1) === '_' ? '_id' : 'id'] == id);
  }

  elementModified(event) {
    if (event.target && event.target.hmElement) event.target.hmElement.update({}, 'elementModified');
  }

  elementMoved(event) {
    if (event.target && event.target.hmElement) event.target.hmElement.onMove();
  }

  _onSelectionUpdate() {
    if (this.skipSelectionEvent) return;
    let element = this.fabricCanvas.getActiveObject();
    if (element && element.selectID) {
      let el = this.element(element.selectID);
      el.select()
      this.options.onElementSelected(el.fabricElement);
    } else this.options.onElementSelected(element);
    setTimeout(() => {
      this.unSelectElements();
    }, 50);
  }

  unSelectElements() {
    let elements = this.fabricCanvas.getActiveObjects();
    if (elements.length <= 1) return;
    this.skipSelectionEvent = true;
    let selectableElements = elements.filter(el => el.type === 'text' || el.hmElement.selectable);
    this.fabricCanvas.discardActiveObject();
    let sel = new fabric.ActiveSelection(selectableElements, {
      canvas: this.fabricCanvas,
    });
    this.fabricCanvas.setActiveObject(sel);
    this.fabricCanvas.requestRenderAll();
    this.skipSelectionEvent = false;
  }

  indNum(element) {
    if (typeof element.individualNumber === 'undefined' || element.explication?.zone) return '';
    return element.individualNumber + (parseInt(this.plan.numerationInfo[element.explication?.rus || element.title]?.count) || 0)
  }

  indNumEnabled(element) {
    return !(element.individualNumberDisabled || this.plan.numerationInfo[element.explication?.rus || element.title]?.disabled || +element.no_number);
  }

  updateNumerationInfo(value, title) {
    if (!this.plan.numerationInfo[title]) this.plan.numerationInfo[title] = {}
    this.plan.numerationInfo[title].disabled = value
    this.plan.numerationInfo = {...this.plan.numerationInfo};
  }

  _getLastAddedElementByType(type) {
    return this.elements.find(el => el.data.type === type);
  }

  _getElementsByType(types) {
    return this.elements.filter(el => types.includes(el.data.type));
  }

  updatePointNumbers() {
    geometryFunctions.calcPointNumbers(this, this._getElementsByType(store.state.pointTypes), this._getElementsByType(['polyline']), this.project);
  }

  updatePolylineOrder() {
    let polylines = this._getElementsByType(['polyline']);
    polylines.sort((a, b) => a.data.order - b.data.order);
    polylines.forEach((p, i) => p.update({order: i + 1}).render());
  }

  setCenterTo(coords) {
    let zoom = this.fabricCanvas.viewportTransform[0]
    let centerOfScreenCoords = this.fabricCanvas.getVpCenter();
    this.fabricCanvas.viewportTransform[4] += (centerOfScreenCoords.x - coords.x) * zoom;
    this.fabricCanvas.viewportTransform[5] += (centerOfScreenCoords.y - coords.y) * zoom;
    this.fabricCanvas.requestRenderAll();
  }

  events() {
    this.fabricCanvas.on('object:modified', this.elementModified);
    this.fabricCanvas.on('object:moving', this.elementModified);
    this.fabricCanvas.on('object:moving', this.elementMoved);
    this.fabricCanvas.on('mouse:down', (event) => {
      this.controlsState.clicked = true;
      this.controlsState.mouseDown = true;
      if (this.controlsState.spacePressed) {
        this.controlsState.isDragging = true;
        this.fabricCanvas.selection = false;
        this.controlsState.lastClickedPosX = event.e.clientX;
        this.controlsState.lastClickedPosY = event.e.clientY;
        this.controlsState.selection = false;
        this.fabricCanvas.setCursor('grabbing');
      } else {
        this.controlsState.selection = true;
        this.fabricCanvas.selection = true;
        this.controlsState.isDragging = false;
      }
    });
    this._keyDown = e => {
      if (e.code === 'Space') {
        this.controlsState.spacePressed = true;
      }
    }
    this._keyUp = e => {
      if (e.code === 'Space') {
        this.controlsState.spacePressed = false;
        this.fabricCanvas.setCursor('default');
        // this.fabricCanvas.defaultCursor = 'default';
      }
    }
    this._keyPress = e => {
      if (e.target.nodeName === 'INPUT') return;
      if (e.code === 'KeyC' && (e.ctrlKey || e.metaKey)) {
        let element = this.fabricCanvas.getActiveObject();
        if (element && element.hmElement) {
          this.copyData = element.hmElement._getData();
        }
      } else if (e.code === 'KeyV' && (e.ctrlKey || e.metaKey)) {
        if (this.copyData) {
          Point.create(this, {}, this.controlsState.lastPos)
        }
        this.copyData = null;
      } else if (e.code === 'Escape') {
        this.fabricCanvas.discardActiveObject().requestRenderAll();
      }
    }
    document.addEventListener('keydown', this._keyDown);
    document.addEventListener('keyup', this._keyUp);
    document.addEventListener('keydown', this._keyPress);
    this.fabricCanvas.on('mouse:move', opt => {
      this.controlsState.lastPos = this.fabricCanvas.getPointer(opt);
      if (this.controlsState.isDragging) {
        this.fabricCanvas.setCursor('grabbing');
        let e = opt.e;
        let vpt = this.fabricCanvas.viewportTransform;
        let dX = e.clientX - this.controlsState.lastClickedPosX;
        let dY = e.clientY - this.controlsState.lastClickedPosY;
        if (Math.abs(dX) < 5 && Math.abs(dY) < 5) return;
        vpt[4] += dX
        vpt[5] += dY
        this.fabricCanvas.requestRenderAll();
        this.controlsState.lastClickedPosX = e.clientX;
        this.controlsState.lastClickedPosY = e.clientY;
      } else if (this.controlsState.spacePressed) {
        this.fabricCanvas.setCursor('grab');
      }
      if (this.controlsState.clicked) {
        this.controlsState.clicked = false;
      }
    });
    this.fabricCanvas.on('mouse:wheel', opt => {
      let delta = opt.e.deltaY;
      let zoom = this.fabricCanvas.getZoom();
      let limits = {min: 1, max: 5};
      //Скорость зума
      zoom *= 0.99 ** delta;
      if (zoom > limits.max) zoom = limits.max;
      if (zoom < limits.min) zoom = limits.min;
      this.fabricCanvas.zoomToPoint({x: opt.e.offsetX, y: opt.e.offsetY}, zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
    });
    this.fabricCanvas.on('selection:created', () => this._onSelectionUpdate());
    this.fabricCanvas.on('selection:updated', () => this._onSelectionUpdate());
    this.fabricCanvas.on('selection:cleared', (event) => {
      if (this.skipSelectionEvent) return;
      if (event.deselected && ['removePoints', 'addPoints'].includes(this.state.mode)) {
        this.fabricCanvas.setActiveObject(event.deselected[0]);
        this.fabricCanvas.requestRenderAll();
        return;
      }
      this._onSelectionUpdate()
    });
    this.fabricCanvas.on('mouse:up', event => {
      if (this.controlsState.isDragging) {
        this.fabricCanvas.setCursor('grab');
      }
      this.controlsState.mouseDown = false;
      this.fabricCanvas.setViewportTransform(this.fabricCanvas.viewportTransform);
      this.controlsState.isDragging = false;
      if (this.state.mode === 'create') {
        if (this.tabInfo.key === 'polygon') {
          if (!this.creatingElement) {
            this.creatingElement = new Polygon(this, {
              points: [],
              type: this.tabInfo.key,
              zoneType: this.elements.length ? +this.elements[this.elements.length - 1].data.zoneType : 1,
              color: this.elements.length ? store.state.polygonSettings.colors[this.elements[this.elements.length - 1].data.zoneType + ''] : store.state.polygonSettings.colors["1"]
            }, {scale: this.project.scale});
            this.elements.unshift(this.creatingElement);
          }
          if (event.target?.hmElement.data._id !== this.creatingElement.data._id) {
            this.creatingElement.addPoint(event.absolutePointer);
            this.options.onElementsChanged();
          }
        } else if (this.tabInfo.key === 'polyline') {
          if (!this.creatingElement) {
            this.creatingElement = new Polyline(this, {
              points: [],
              type: this.tabInfo.key,
              order: this.elements.filter(el => el.data.type === 'polyline').length + 1
            }, {scale: this.project.scale});
            this.elements.push(this.creatingElement);
          }
          if (event.target?.hmElement.data._id != this.creatingElement.data._id) this.creatingElement.addPoint(event.absolutePointer);
        } else if (store.state.pointTypes.includes(this.tabInfo.key) && !event.target?.hmElement) {
          Point.create(this, event);
        }
      } else {
        let selectedElement = this.fabricCanvas.getActiveObject()?.hmElement;
        if (selectedElement) {
          if (this.controlsState.clicked) {
            if (['polygon', 'polyline'].includes(selectedElement.data.type)) {
              if (this.state.mode === 'addPoints') {
                selectedElement.addPoint(event.absolutePointer, true);
              }
              if (this.state.mode === 'removePoints') {
                selectedElement.removePoint(event.absolutePointer)
              }
            }
          }
        }
      }
      if (event.target?.hmElement?.updatePointDependencies) event.target?.hmElement?.updatePointDependencies();
      this.controlsState.clicked = false;
    });
  }
}

export default HMCanvas;
