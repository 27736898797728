<template>
  <el-dialog :visible.sync="isVisible" title="Доступна новая версия">
    <div class="dialog-actions">
      <el-button @click="isVisible = false">Позже</el-button>
      <el-button @click="reload" type="primary">Обновить</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "NewVersionWarningDialog",
  data() {
    return {
      isVisible: false,
    }
  },
  methods: {
    open() {
      this.isVisible = true;
    },
    reload() {
      window.location.reload();
    }
  }
}
</script>

<style scoped>

</style>