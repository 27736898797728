<template>
  <header class="header">
    <div class="header-inner" :class="{'header-inner_design_page': currentRouteName === 'ProjectDesignPage'}">
      <div class="header-body">
        <router-link to="/" class="header-logo" :class="{'header-logo__small': currentRouteName !== 'Homepage'}">
          ¶þŗǿĉę55ōρ қőŗέ
        </router-link>
        <template v-if="currentRouteName === 'ProjectDesignPage'">
          <div @click="$router.push({name: 'ProjectPage', params: {id: project.id}})" class="header-body-project-title">{{ project.title }}</div>
          <div class="header-project-scale">
            <div class="header-project-scale__label">Масштаб элементов</div>
            <el-input class="header-project-scale__input" placeholder="100%" v-model="project.scale"
                      @change="onScaleChange"
                      type="number"/>
          </div>
          <div class="header-body-design-tabs">
            <el-button @click="onDesignTabSelect('marking')"
                       class="header-project-tabs__tab header-project-design__tab"
                       :class="{'active': currentDesignTab === 'marking'}">Разметка
            </el-button>
            <el-button @click="onDesignTabSelect('arrangement')"
                       class="header-project-tabs__tab header-project-design__tab"
                       :class="{'active': currentDesignTab === 'arrangement'}">
              Расстановка
            </el-button>
          </div>
        </template>
      </div>
      <template v-if="currentRouteName === 'Homepage'">
        <el-button @click="addProject">+ Новый проект</el-button>
        <div class="header-search">
          <el-button v-if="!isSearchInputVisible" @click="isSearchInputVisible = true" type="text"
                     style="width: 200px"
                     class="header-search-button">
            <i class="icon-search" style="margin-right: 4px"/>
            Поиск проекта
          </el-button>
          <el-input v-if="isSearchInputVisible"
                           style="width: 200px"
                    clearable
                    @input="onSearchInput"
                           v-model="searchValue" placeholder="Поиск проекта">
            <template #prefix>
              <i class="icon-search"/>
            </template>
          </el-input>
        </div>
        <div class="header-dictionaries">
          <router-link :to="{name: 'Settings'}" class="header-dictionaries-item">Базы данных</router-link>
        </div>
      </template>
      <el-dropdown trigger="click">
        <div class="user-dropdown-button" style="display: flex;align-items: center;gap: 5px;text-transform: capitalize; cursor: pointer">
          <i class="icon-account_circle" style="font-size: 24px"/>
          {{$store.state.userData.user.login}}
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-if="user.id" @click.native="openConfirmLogout">
            <i class="icon-logout" style="margin-right: 4px"/>
            Выйти
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <template v-if="currentRouteName === 'ProjectPage'">
      <div class="header-inner header-project">
        <div v-if="isEditTitle" class="project-title-input">
          <div class="project-title-input__inner">
            <el-input v-model="project.title" placeholder="Название проекта"
                      @input="onProjectTitleInput"
                      @change="onProjectTitleChanged"/>
            <div v-if="errorTitle" class="input-error">{{errorTitle}}</div>
          </div>
          <el-button type="text" @click="onProjectTitleChanged">Готово</el-button>
        </div>
        <div v-else class="project-title">{{ project.title }}
          <div class="project-title-icon" @click="isEditTitle = true; errorTitle = ''">
            <i class="icon-edit"/>
          </div>
        </div>
        <router-link v-if="project?.plans?.length" class="project-planning-button" :to="{name: 'ProjectDesignPage',
        params: {id: project.id, plan_id: project?.plans[0]?.id}}">
          Проектирование
          <i class="el-icon el-icon-right"/>
        </router-link>
      </div>
      <div class="header-inner header-project-tabs">
        <el-button v-for="(tab, key) in tabs" :key="key" @click="onTabSelect(key)"
                   class="header-project-tabs__tab" :class="{'active': key === currentTab}">{{ tab.label }}
        </el-button>
      </div>
    </template>
    <AddProjectDialog ref="add_project_dialog"/>
    <ConfirmDialog ref="confirm_logout_dialog" title="Вы уверены, что хотите выйти?" confirm-button="Да, выйти"
    @confirm="logout"/>
  </header>
</template>

<script>
import AddProjectDialog from "@/dialogs/AddProjectDialog";
import ConfirmDialog from "@/dialogs/ConfirmDialog";
import projectMixins from "@/mixins/projectMixins";
import globalMixins from "@/globalMixins";

export default {
  name: "TheHeader",
  mixins: [projectMixins],
  components: {
    ConfirmDialog,
    AddProjectDialog,
  },
  data() {
    return {
      searchValue: '',
      isSearchInputVisible: true,
      project: {},
      currentTab: 'plans',
      currentDesignTab: 'marking',
      tabs: {
        plans: {label: 'Планы'},
        settings: {label: 'Настройки'},
        docs: {label: 'Документация'},
      },
      isEditTitle: false,
      scale: '50%',
      titleBeforeEdit: '',
      errorTitle: ''
    }
  },
  methods: {
    addProject() {
      this.$refs.add_project_dialog.open();
    },
    onScaleChange(v) {
      if (v > 400) this.project.scale = 400;
      else if (v < 10) this.project.scale = 10;
      this.$store.state.eventBus.$emit('on-project-scale-changed', this.project);
    },
    onProjectTitleInput() {
      const debouncedInput = globalMixins.debounce(()=>{
        this.checkProjectName(this.project).then(data=>{
          if (!data.response) {
            this.errorTitle = 'Проект с таким названием уже есть';
          }
        })
      }, 300);
      debouncedInput();
    },
    onProjectTitleChanged() {
        this.isEditTitle = false;
        this.errorTitle = '';
        this.checkProjectName(this.project).then(data=>{
          if (data.response) {
            this.$store.state.eventBus.$emit('on-project-changed', this.project);
          } else this.project.title = this.titleBeforeEdit;
        })
    },
    onTabSelect(tab) {
      this.currentTab = tab;
      this.$store.state.eventBus.$emit('on-select-tab', tab);
    },
    onDesignTabSelect(tab) {
      this.currentDesignTab = tab;
      this.$store.state.eventBus.$emit('on-select-design-tab', tab);
    },
    openConfirmLogout() {
      this.$refs.confirm_logout_dialog.open();
    },
    onSearchInput(q) {
      this.$store.state.eventBus.$emit('on-search-project', q);

    },
    logout() {
      this.$api.request('auth/logout').then(()=>{
        window.location.href = '/';
        // this.$store.commit('setUser', {});
        // if (this.$route.name !== 'Homepage')
        //   this.$router.replace('/');
        // this.$refs.confirm_logout_dialog.close();
      })
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    user() {
      return this.$store.state.userData.user;
    },
  },
  watch: {
    '$route.name': function () {
      this.currentTab = 'plans';
      this.isEditTitle = false;
    }
  },
  mounted() {
    this.currentTab = this.$route.query.t || 'plans';

    if (this.$store.state.arrangementTabs.includes(this.$route.params.elementType)) {
      this.currentDesignTab = 'arrangement';
    }
  },
  created() {
    this.$store.state.eventBus.$on('project', (data) => {
      this.project = data;
      this.titleBeforeEdit = this.project.title;
    });
  },
  beforeDestroy() {
    this.$store.state.eventBus.$off('project');
  }
}
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  border-bottom: 1px solid var(--primary-color);

  &-inner {
    width: 100%;
    max-width: var(--desktop-max-width);
    margin: 0 auto;
    padding: 16px var(--hor-padding);
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 16px;
    &_design_page {
     max-width: 100%;
      .header-body-project-title {
        margin-left:auto;
      }
    }
  }

  &-logo {
    font-size: 22px;
    text-decoration: none;

    &__small {
      font-size: 14px;
    }
  }

  &-search {
    margin-left: auto;
    display: flex;
    align-items: center;
    width: 200px;

    &-button {
      width: 100%;
      font-size: 20px;
    }
  }

  &-dictionaries {
    display: flex;
    flex-direction: column;
    &-item {
      white-space: nowrap;
      margin-bottom: 8px;
      display: block;
      font-size: 14px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .header-project {
    align-items: flex-end;
  }

  .project-title-input {
    display: flex;
    column-gap: 8px;
    align-items: flex-end;
    width: 50%;
  }

  .project-title {
    display: flex;
    font-size: 28px;
    line-height: 38px;
    &-icon {
      position: relative;
      margin-left: 4px;
      cursor: pointer;
    }
  }

  .project-planning-button {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 14px;
  }

  .header-project-tabs {
    padding-bottom: 0;
    justify-content: left;
    &__tab {
      margin-left: 0;
      border: none;

      &.active {
        background: var(--primary-color);
        color: #FFFFFF;
      }
      &.header-project-design__tab {
        width: 100px;
        &.active {
          background: transparent;
          font-weight: bold;
          color: var(--primary-color)
        }
        &:hover {
          border-bottom: 1px solid var(--primary-color);
        }
      }
    }
  }

  .header-body {
    display: flex;
    align-items: flex-end;
    column-gap: 8px;
    width: 100%;

    &-project-title {
      font-size: 24px;
      cursor: pointer;
      width: fit-content;
    }

    &-design-tabs {
      display: flex;
      column-gap: 8px;
      justify-content: flex-end;
    }
  }
  .header-project-scale {
    display: flex;
    align-items: center;
    margin-left: auto;
    &__label {
      margin-right: 8px;
      text-align: right;
      font-size: 12px;
      max-width: 100px;
    }
    &__input {
      max-width: 60px;
    }
  }
}
</style>
