<template>
  <div class="page">
    <h1 class="page-title">Базы данных</h1>
    <DocumentationList :docs="uploadFiles" custom-style="grid-template-columns: 250px 250px 250px 1fr"/>
  </div>
</template>

<script>
import DocumentationList from "@/components/DocumentationList";

export default {
  name: "Settings",
  components: {DocumentationList},
  data() {
    return {
      docs: {}
    }
  },
  computed: {
    uploadFiles() {
      return this.$store.state.userData.uploadFiles;
    }
  }
}
</script>

<style scoped>

</style>