export default {
  distance(point1, point2) {
    return Math.sqrt(Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2))
  },
  pDistance(point, pointA, pointB) {
    var A = point.x - pointA.x;
    var B = point.y - pointA.y;
    var C = pointB.x - pointA.x;
    var D = pointB.y - pointA.y;

    var dot = A * C + B * D;
    var len_sq = C * C + D * D;
    var param = -1;
    if (len_sq != 0) //in case of 0 length line
      param = dot / len_sq;

    var xx, yy;

    if (param < 0) {
      xx = pointA.x;
      yy = pointA.y;
    } else if (param > 1) {
      xx = pointB.x;
      yy = pointB.y;
    } else {
      xx = pointA.x + param * C;
      yy = pointA.y + param * D;
    }

    var dx = point.x - xx;
    var dy = point.y - yy;
    return Math.sqrt(dx * dx + dy * dy);
  },
  projectionLength(p, pA, pB) {
    let a = this.distance(pA, pB);
    let b = this.distance(p, pB);
    let c = this.distance(p, pA);
    let l = c * c - b * b + a * a;
    return l >= 0 ? Math.sqrt(l) : -Math.sqrt(-l);
  },
  checkPolygons(data, polygons) {
    let zones = [];
    polygons.forEach(p => {
      if (this.inside(data.point, p.points)) zones.push(p.zoneType)
    });
    return zones.filter((value, index, array) => array.indexOf(value) === index).sort();
  },
  inside(point, polygonPoints) {
    var x = point.x, y = point.y;
    var inside = false;
    for (var i = 0, j = polygonPoints.length - 1; i < polygonPoints.length; j = i++) {
      var xi = polygonPoints[i].x, yi = polygonPoints[i].y;
      var xj = polygonPoints[j].x, yj = polygonPoints[j].y;

      var intersect = ((yi > y) !== (yj > y))
        && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) inside = !inside;
    }
    return inside;
  },
  calcPointNumbers(hmCanvas, points, polylines, project) {
    polylines.sort((a, b) => a.data.order - b.data.order);
    // polylines.forEach((p, i) => p.update({order: i + 1}).render());
    points.forEach(p => {
      let min = Number.MAX_SAFE_INTEGER;
      let lineIndex = -1;
      let segmentIndex = -1;
      let projectionLength = -1;
      for (let j = 0; j < polylines.length; ++j) {
        let linePoints = polylines[j].data.pointsCanvas;
        if (linePoints.length < 2) continue;
        for (let i = 0; i < linePoints.length - 1; ++i) {
          let d = this.pDistance(p.data.pointCanvas, linePoints[i], linePoints[i + 1]);
          if (d < min) {
            min = d;
            lineIndex = j;
            segmentIndex = i;
            projectionLength = this.projectionLength(p.data.pointCanvas, linePoints[i], linePoints[i + 1]);
          }
        }
      }
      p.data.numberInfo = [
        lineIndex,
        segmentIndex,
        projectionLength,
      ]
    });
    points.sort((a, b) => {
      for (let i = 0; i < a.data.numberInfo.length; ++i) {
        if (a.data.numberInfo[i] !== b.data.numberInfo[i]) return a.data.numberInfo[i] - b.data.numberInfo[i];
      }
      return 0;
    });
    let pointRus = p => p.data.explication?.rus || p.data.title;
    let rus = {};
    let total = {};
    let rusBlocked = {};
    points.forEach(p => {
      let title = pointRus(p);
      rus[title] = 0;
      total[title] = (total[title] || 0) + 1;
      if (+p.data.no_number) rusBlocked[title] = 1;
    });
    let number = 0;
    points = points.map((p, i) => {
      let title = pointRus(p);
      if (title) {
        rus[title]++;
        p.data.individualNumber = rus[title];
        p.data.indNumTotal = total[title];
        p.data.individualNumberDisabled = !!rusBlocked[title];
      }
      if (p.data.type === 'door' && (project.numeration_staff || !p.data.explication || p.data.explication.zone === '')) p.data.number = ++number;
      else p.data.number = 0;
      if (!polylines.length) p.data.number = 0;
      p.update({}, 'calcPointNumbers').render();
      return p;
    });
    hmCanvas.plan.numerationInfo.totalLocal = total;
  }
}
