<template>
  <div>
    <div class="side-menu-forms-list__item_col">
      <RoomZones :zones="element.zones"/>
      <div class="side-menu-forms-list__item__label">
        В экспликации
      </div>
      <el-autocomplete
          ref="title_input"
          style="width: 100%"
          :fetch-suggestions="(q, cb)=>searchExplication(q, cb)"
          @change="onChange"
          @select="(v)=>onExplicationSelect(v, element)"
          v-model="element.title"/>
      <span v-if="!element.explication" style="font-size: 12px;color: red;">
                          {{ element.title ? 'Требуется добавление в словарь' : 'Введите экспликацию' }}
                        </span>
    </div>
    <div class="side-menu-forms-list__item_row">
      <div class="side-menu-forms-list__item_col" style="flex: 1;align-items: start;">
        <div class="side-menu-forms-list__item__label">Русский</div>
        <div class="side-menu-forms-list__item_number__value">
          {{ generatedRusName }}
        </div>
      </div>
      <div class="side-menu-forms-list__item_col">
        <div class="side-menu-forms-list__item__label">Номер</div>
        <div class="side-menu-forms-list__item_number__value"
             :style="`opacity: ${hmCanvas.indNumEnabled(element) ? 1:0.2}`">
          {{
            +hmCanvas.plan.numerationInfo[element.explication?.rus || element.title]?.totalCnt === 1 && element.indNumTotal == 1 ? '' : hmCanvas.indNum(element)
          }}
        </div>
      </div>
      <div class="side-menu-forms-list__item_col">
        <div class="side-menu-forms-list__item__label">Не нужен</div>
        <el-checkbox :true-label="1" :false-label="0"
                     :value="$store.state.plan.numerationInfo[element.explication?.rus || element.title]?.disabled"
                     @change="val=>{$store.state.updateNumerationInfo(val, element.explication?.rus || element.title);$forceUpdate();}"/>
        <!--          <el-checkbox true-label="1" false-label="0" v-model="element.no_number" @change="update(element)"/>-->
      </div>
    </div>
    <div v-if="+hmCanvas.project.eng === 1" class="side-menu-forms-list__item_col">
      <div class="side-menu-forms-list__item__label">Перевод</div>
      <div class="side-menu-forms-list__item_number__value">
        {{ element.explication ? element.explication.eng : '---' }}
      </div>
    </div>
    <div
        v-if="+project.numeration_type !== 3 && (project.numeration_staff || element.explication?.zone !== 'Служебное')
&& element.type !== 'not_room'"
        class="side-menu-forms-list__item_col">
      <div class="side-menu-forms-list__item__label">Номер пользовательской нумерации</div>
      <div class="side-menu-forms-list__item_number__value">
        {{ hmCanvas.genNumber(element) }}
      </div>
    </div>
    <div class="side-menu-forms-list__item_col">
      <div class="side-menu-forms-list__item__label">Тип носителя</div>
      <el-select v-model="element.signs" multiple allow-create filterable
                 placeholder="Без маркировки" @change="update(element)"
                 :no-data-text="'  '">
        <el-option
            v-for="(option, i) in element.optionsSigns?.length ? Object.values($store.state.userData.signs).filter(sign=>element.optionsSigns.includes(sign.name)) : $store.state.userData.signs"
            :key="i" :value="option.name"
            :label="option.name"/>
      </el-select>
    </div>
  </div>
</template>
<script>
import RoomZones from "@/components/RoomZones";
import store from "@/store";

export default {
  name: 'RoomForm',
  components: {RoomZones},
  props: ['element', 'hmCanvas', 'requestsQueue', 'project', 'numerationInfo'],
  watch: {
    'hmCanvas.plan.numerationInfo'() {
      console.log('watch');
    }
  },
  methods: {
    log(event) {
      console.log('log ' + event + ' ' + this.element.title);
    },
    setExplication(explication) {
      let updateFields = ['eng', 'rus'];
      this.element.title = explication.name;
      if (explication.zone && !this.hmCanvas.plan.numerationInfo.totalLocal[explication.rus]) {
        this.$store.state.updateNumerationInfo(1, explication.rus);
      }
      this.element.explication = explication;
      this.element.optionsSigns = explication.signs;
      if (this.element.optionsSigns.length) {
        this.element.signs = this.element.optionsSigns.slice(0, this.element.connection_type === 'elevator' ? 2 : 1);
      }
      updateFields.forEach(field => this.element[field] = explication[field]);
    },
    // onBlurExplication(val, element) {
    //   this.searchExplication(val, (explications) => {
    //     if (val.toLowerCase() === explications[0].name.toLowerCase())
    //       this.onExplicationSelect(explications[0], element);
    //     else {
    //       element.title = val;
    //       element.explication = null;
    //       element.signs = [];
    //       element.optionsSigns = this.$store.state.userData.signs;
    //       // this.update(element);
    //     }
    //   });
    // },
    searchExplication(q, cb, equals = false) {
      if (!q) q = '';
      q = q.trim().toLowerCase();
      cb(store.state.userData.explicationsAll.filter(e => {
        let elementName = e.name.toLowerCase();
        return equals && q === elementName || !equals && elementName.indexOf(q) === 0;
      }).slice(0, 10).map(e => {
        return {value: e.name, ...e}
      }))
    },
    onExplicationSelect(explication, element) {
      let updateFields = ['eng', 'rus'];
      element.title = explication.name;
      if (explication.zone && !this.hmCanvas.plan.numerationInfo.totalLocal[explication.rus]) {
        this.$store.state.updateNumerationInfo(1, explication.rus);
        element.no_number = 1;
      }
      element.explication = explication;
      element.optionsSigns = explication.signs;
      if (element.optionsSigns.length) {
        element.signs = element.optionsSigns.slice(0, element.connection_type === 'elevator' ? 2 : 1);
      }
      updateFields.forEach(field => element[field] = explication[field]);
      this.update(element);
    },
    onChange(value) {
      window.title_input = this.$refs.title_input;
      this.searchExplication(value, (explications) => {
        if (!explications.length) return;
        this.onExplicationSelect(explications[0], this.element);
        this.$refs.title_input.close();
      }, true);
    },
    update(element) {
      console.log('update', element);
      let el = this.hmCanvas.element(element._id);
      if (el) {
        this.hmCanvas.element(element._id).update(element).render();
        this.hmCanvas.updatePointNumbers();
      } else {
        this.requestsQueue.add(element);
      }
    },
  },
  computed: {
    generatedRusName() {
      let isServiceStr = this.element.explication?.zone === 'Служебное' && this.element.zones.includes(3);
      let isStaffStr = this.element.explication?.zone === 'Техническое' && this.element.zones.some(v => [2, 3].includes(v));
      /*посетители везде по своему
              служебные в посетителях - Служебное помещение, у себя и в тех. - по своему explication.zone = "Служебное"
              тех. помещение - везде Техническое помещение, у себя - по своему explication.zone = "Техническое"*/
      return this.element.explication ?
          isServiceStr ? 'Служебное помещение' : isStaffStr ? 'Техническое помещение' :
              this.element.explication.rus : '---';
    }
  }
}
</script>
