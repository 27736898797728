<template>
  <div :style="zones && zones.length?'margin-top: -10px;':''">
    <span v-for="zone in zones" :key="zone" :style="{background:$store.state.polygonSettings.colors[zone]}"
          style="font-size: 8px;background: rgb(185, 231, 180);padding: 2px 5px;border-radius: 10px;margin-right: 5px;white-space: nowrap;">{{
        $store.state.polygonSettings.zoneTypes[zone]
      }}</span>
  </div>
</template>
<script>
export default {
  name: 'RoomZones',
  props: ['zones'],
}
</script>
