<template>
  <div class="noInternetConnection" :class="{'noInternetConnectionVisible': isVisible}">
    <div class="noInternetConnectionInner">Нет соединения, изменения не сохраняются</div>
  </div>
</template>

<script>
export default {
  name: "BadConnectionBlock",
  data() {
    return {
      isVisible: false,
    }
  }
}
</script>

<style scoped lang="scss">
.noInternetConnection {
  position: fixed;
  bottom: 0;
  width: 100%;
  transform: translateY(42px);
  transition: transform .3s linear;
  will-change: transform;
  z-index: 9999;
  background: #c48c8c;
}
.noInternetConnectionInner {
  max-width: var(--desktop-max-width);
  padding: 0 60px;
  display: flex;
  height: 42px;
  justify-content: center;
  align-items: center;
}
.noInternetConnectionVisible {
  transform: translateY(0);
}
</style>