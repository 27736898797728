<template>
  <div id="app">
    <TheHeader/>
    <router-view/>
    <InfoDialog ref="info_dialog"/>
    <AuthDialog ref="auth_dialog" @on-success-auth="getUser"/>
    <BadConnectionBlock ref="bad_connection_block"/>
    <NewVersionWarningDialog ref="new_version_warning_dialog"/>
  </div>
</template>

<script>

import TheHeader from "@/components/TheHeader";
import InfoDialog from "@/dialogs/InfoDialog";
import AuthDialog from "@/dialogs/AuthDialog";
import BadConnectionBlock from "@/components/BadConnectionBlock";
import NewVersionWarningDialog from "@/dialogs/NewVersionWarningDialog";
import globalMixins from "@/globalMixins";
export default {
  name: 'App',
  mixins: [globalMixins],
  components: {
    NewVersionWarningDialog,
    BadConnectionBlock,
    AuthDialog,
    InfoDialog,
    TheHeader
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    }
  },
  methods: {
    getUser() {
      this.$api.request('auth/user').then((data)=>{
        if (data.response) {
          this.$store.commit('setUserData', data);
          if ( globalMixins.convertVersion(this.userData.version) > globalMixins.convertVersion(this.$store.state.currentVersion))
            this.openNewVersionDialog();
        }
        else this.$refs.auth_dialog.open();
      })
    },
    openNewVersionDialog() {
      this.$refs.new_version_warning_dialog.open();
    }
  },
  beforeMount() {
    this.getUser();
  },
  created() {
    this.$store.state.eventBus.$on('open-info-dialog', (params)=>{
      this.$refs.info_dialog.open(params);
    });
    this.$store.state.eventBus.$on('login', ()=>{
      this.$refs.auth_dialog.open();
    });
    this.$store.state.eventBus.$on('connection-state', val=>{
      this.$refs.bad_connection_block.isVisible = val;
    });

  },
  beforeDestroy() {
    this.$store.state.eventBus.$off('open-info-dialog');
    this.$store.state.eventBus.$off('login');
    this.$store.state.eventBus.$off('connection-state');
  }
}
</script>

<style>

</style>
