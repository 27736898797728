<template>
  <div class="docs-list">
    <div class="docs-list-item" :style="customStyle" v-for="(item, i) in docs" :key="i">
      <div class="docs-list-item__name" v-if="item.name">{{ item.name}}</div>
      <div v-if="item.filename" class="docs-list-item__name">{{ item.filename }}</div>
      <div v-if="item.date" class="docs-list-item__name">{{ item.date }}</div>
      <a v-if="item.link" @click="onDownloadLinkClick(item, i)" class="docs-list-item__link">
        <i class="el-icon el-icon-bottom" style="margin-right: 4px"/>Скачать</a>
      <div v-else class="docs-list-item__link" @click="upload(i)">
        <input :ref="`file_input_${i}`" type="file" accept="text/csv" style="display: none"
               @change="e=>onFileInputChange(e, i)"/>
        <template v-if="item.loading">
          <i class="el-icon el-icon-loading"></i>
        </template>
        <template v-else>
          <i class="el-icon el-icon-top" style="margin-right: 4px"/>
          Загрузить
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DocumentationList",
  props: ['docs', 'project', 'customStyle'],
  data() {
    return {}
  },
  methods: {
    upload(key) {
      this.$refs[`file_input_${key}`][0].click();
    },
    onFileInputChange(e, key) {
      let files = Array.from(e.target.files);
      files.forEach(file => {
        if (file.size > 1048576 * 25) {
          this.$store.state.eventBus.$emit('open-info-dialog', {
            title: 'Превышен размер файла',
            subtitle: 'Размер файла не должен превышать 25 мб'
          })
          return;
        }
        let formData = new FormData();
        formData.append('file', file, file.name);
        this.docs[key].loading = true;
        this.$forceUpdate();
        this.$api.request(key ? `settings/${key}` : 'plan/upload', formData, true).then((data) => {
          window.location.reload();
          this.docs[key].loading = false;
          this.$refs[`file_input_${key}`][0].value = '';
          this.$forceUpdate();
        }).catch(() => {
          this.docs[key].loading = false;
          this.$refs[`file_input_${key}`][0].value = '';
          this.$forceUpdate();
        })
      });
    },
    onDownloadLinkClick(item, i) {
      if (item.name === 'Расстановка.pdf') {
        this.$router.push({
          name: 'ProjectDesignPage',
          params: {id: this.project.id, plan_id: this.project?.plans[0]?.id, elementType: 'sign'},
          query: {download: '1'},
        });
      } else window.open(item.link, '_blank');
    }
  }
}
</script>

<style scoped lang="scss">

.docs-list-item {
  display: grid;
  grid-template-columns: 300px 1fr;
  padding: 12px 0;
  border-bottom: 1px solid var(--primary-color);

  &:last-child {
    border-bottom: none;
  }

  &__link {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 500;
    width: fit-content;
  }
}
</style>
