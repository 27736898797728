import {fabric} from "fabric";
import Polygon from "./polygon";
import geometryFunctions from "@/pages/project/canvas_methods/algorithms/geometryFunctions";


function calcArrowAngle(startPoint, endPoint) {
  return Math.atan2(endPoint.y - startPoint.y, endPoint.x - startPoint.x);
}

class Polyline extends Polygon {
  static color = 'red';

  constructor(hmCanvas, data = {}, options = {}) {
    super(hmCanvas, data, options);
    this.strokeWidth = 2 * (hmCanvas.project.scale / 100);
  }

  createElement() {
    let element;
    if (this.data.pointsCanvas.length > 1) {
      element = new fabric.Polyline(this.data.pointsCanvas, {
        fill: false,
        stroke: Polyline.color,
        strokeWidth: this.strokeWidth,
        hasControls: true,
        hoverCursor: 'pointer',
        hasBorders: false,
        objectCaching: false,
        lockMovementX: true,
        lockMovementY: true,
        perPixelTargetFind: true,
        transparentCorners: false,
      });
      var lastControl = element.points.length - 1;
      element.cornerStyle = 'circle';
      element.cornerColor = '#b9b9b9'
      element.cornerStrokeColor = '#565656'
      element.controls = element.points.reduce((acc, point, index) => {
        acc['p' + index] = new fabric.Control({
          positionHandler: (dim, finalMatrix, fabricObject) => this._polygonPositionHandler(dim, finalMatrix, fabricObject, index),
          actionHandler: this._anchorWrapper(index > 0 ? index - 1 : lastControl, (...args) => this._actionHandler(...args)),
          actionName: 'modifyPolygon',
          pointIndex: index,
          radius: 5,
          hoverCursor: 'pointer',
        });
        return acc;
      }, {});
    }
    return element;
  }

  _findIndexForPoint(point, points) {
    let minD = Number.MAX_SAFE_INTEGER;
    let index = -1;
    points.forEach((p, i) => {
      if (i === points.length - 1) return;
      let d = geometryFunctions.pDistance(point, p, points[(i + 1) % points.length]);
      if (d < minD) {
        minD = d;
        index = i;
      }
    })
    return index + 1;
  }

  addPoint(point, findIndex = false) {
    if (findIndex && this.fabricElement.points) {
      let points = this.fabricElement.points;
      let l = points.length;
      let insertAt = this._findIndexForPoint(point, points);
      let dToLastPoint = geometryFunctions.distance(point, points[l - 1]);
      let dToSegment = geometryFunctions.pDistance(point, points[l - 1], points[l - 2]);
      if (Math.abs(dToLastPoint - dToSegment) < 0.1 && insertAt === l - 1) this.data.pointsCanvas.push(point);
      else this.data.pointsCanvas.splice(insertAt, 0, point);
    } else {
      this.data.pointsCanvas.push(point);
    }
    this.render(true).update();
    this.select();
    this.hmCanvas.updatePointNumbers();
  }

  render(firstTime = false) {
    let selectedID;
    if (this.fabricElement) {
      selectedID = this.hmCanvas.fabricCanvas.getActiveObject()?.hmElement?.data?._id;
      this.hmCanvas.fabricCanvas.remove(this.fabricElement);
    }
    this.fabricElement = this.createElement();
    this.renderArrow()
    if (this.fabricElement) this.hmCanvas.fabricCanvas.add(this.fabricElement);
    this.renderFirstPoint();
    this.fabricElement.hmElement = this;
    if (selectedID && selectedID == this.data._id) this.hmCanvas.fabricCanvas.setActiveObject(this.fabricElement);

    return this;
  }

  renderFirstPoint() {
    if (this.fabricFirstPoint) this.hmCanvas.fabricCanvas.remove(this.fabricFirstPoint);
    let points = (this.fabricElement && this.fabricElement.points) ? this.fabricElement.points : this.data.pointsCanvas
    this.fabricFirstPoint = new fabric.Circle({
      radius: 6 * (this.hmCanvas.project.scale / 100),
      top: points[0].y,
      left: points[0].x,
      fill: Polyline.color,
      originX: 'center',
      originY: 'center',
      selectID: this.data._id,
      hasControls: false,
      lockMovementX: true,
      lockMovementY: true,
    });
    // this.fabricFirstPoint.onSelect();
    this.hmCanvas.fabricCanvas.add(this.fabricFirstPoint);
    if (this.fabricFirstPointText) this.hmCanvas.fabricCanvas.remove(this.fabricFirstPointText);
    let fontSize = 6 * (this.hmCanvas.project.scale / 100);
    this.fabricFirstPointText = new fabric.Text(this.data.order + '' || '1', {
      fontSize: fontSize,
      fontFamily: 'Arial',
      fontWeight: 600,
      originX: 'center',
      originY: 'center',
      fill: '#FFFFFF',
      hasControls: false,
      lockMovementX: true,
      lockMovementY: true,
      evented: false,
      top: points[0].y,
      left: points[0].x,
    });
    this.hmCanvas.fabricCanvas.add(this.fabricFirstPointText);
    if (this.data.pointsCanvas.length === 1) {
      this.fabricElement = this.fabricFirstPoint;
      this.fabricElement.hmElement = this;
    }
  }

  renderArrow() {
    if (this.fabricArrow) this.hmCanvas.fabricCanvas.remove(this.fabricArrow);
    if (this.data.pointsCanvas.length <= 1) return;
    const startPoint = this.fabricElement.points[this.data.pointsCanvas.length - 2];
    const endPoint = this.fabricElement.points[this.data.pointsCanvas.length - 1];
    let angle = calcArrowAngle(startPoint, endPoint) + Math.PI / 2;
    let width = 7 * (this.hmCanvas.project.scale / 100);
    let height = 10 * (this.hmCanvas.project.scale / 100);
    this.fabricArrow = new fabric.Triangle({
      width,
      height,
      fill: Polyline.color,
      left: endPoint.x + height * Math.sin(angle) - (width / 2 + 0.5) * Math.cos(angle),
      top: endPoint.y - height * Math.cos(angle) - (width / 2 + 0.5) * Math.sin(angle),
      angle: angle * 180 / Math.PI,
      selectID: this.data._id,
      hasControls: false,
      lockMovementX: true,
      lockMovementY: true,
    });
    this.hmCanvas.fabricCanvas.add(this.fabricArrow);
  }

  onCornersMove() {
    this.renderArrow();
    this.renderFirstPoint();
    this._getData()
    this.hmCanvas.updatePointNumbers();
  }

  remove() {
    super.remove();
    this.hmCanvas.fabricCanvas.remove(this.fabricFirstPoint);
    this.hmCanvas.fabricCanvas.remove(this.fabricFirstPointText);
    this.hmCanvas.fabricCanvas.remove(this.fabricArrow);
    this.hmCanvas.updatePolylineOrder();
    this.hmCanvas.updatePointNumbers();
  }
}

export default Polyline;
