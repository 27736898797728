<template>
  <div class="auth-error">
    <div class="auth-error-title">Вы не авторизованы</div>
    <div class="auth-error-subtitle">Авторизуйтесь, чтобы получить доступ к функционалу приложения</div>
    <el-button @click="login" class="login-button">Войти</el-button>
  </div>
</template>

<script>
export default {
  name: "AuthErrorBlock",
  methods: {
    login() {
      this.$store.state.eventBus.$emit('login');
    }
  }
}
</script>

<style scoped lang="scss">
.auth-error {
  margin: 0 auto;
  padding: 50px 0;
  text-align: center;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  &-title {
    margin-bottom: 40px;
    font-size: 32px;
  }
  &-subtitle {
    margin-bottom: 24px;
    font-size: 20px;
  }
  .login-button {
    max-width: 220px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
}
</style>