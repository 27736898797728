<template>
  <el-dialog :visible.sync="dialogVisible" :title="customTitle ?? title" width="360px" :align-center="true">
    <div v-if="subtitle" class="confirm-dialog-subtitle">{{subtitle}}</div>
    <div class="confirm-dialog-actions">
      <el-button @click="decline" :disabled="loading">{{ declineButton || 'Нет' }}</el-button>
      <el-button @click="confirm" :loading="loading" type="primary">
        {{ loading ? '' : confirmButton || 'Да' }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "ConfirmDialog",
  props: ['title', 'declineButton', 'confirmButton'],
  emits: ['confirm', 'decline'],
  data() {
    return {
      dialogVisible: false,
      ids: null,
      loading: false,
      customTitle: null,
      subtitle: null
    }
  },
  methods: {
    open(ids, title=null, subtitle=null) {
      this.loading = false;
      this.ids = null;
      this.customTitle = null;
      this.subtitle = null;
      this.dialogVisible = true;
      this.ids = ids;
      this.customTitle = title;
      this.subtitle = subtitle;
      window.addEventListener('keypress', this.onKeyPress)
    },
    decline() {
      this.$emit('decline');
      this.close();
    },
    onKeyPress(event) {
      if (event.code === 'Enter') this.confirm();
    },
    confirm() {
      this.loading = true;
      this.$emit('confirm', this.ids);
    },
    close() {
      this.loading = false;
      this.dialogVisible = false;
      window.removeEventListener('keypress', this.onKeyPress)
    }
  }
}
</script>

<style scoped>
.confirm-dialog-subtitle {
  margin-bottom: 24px;
  word-break: break-word;
}
</style>
