import {fabric} from "fabric";
import store from "@/store";
import HMElement from "@/pages/project/canvas_methods/elements/HMElement";

class Pictogram extends HMElement {
  constructor(hmCanvas, data, options) {
    super(hmCanvas, data, options);
    this.pictogramName = store.state.userData.signs[this.data.sign].pictogram;
    this.pictogramColor = store.state.userData.signs[this.data.sign].color;
    this.rotatable = hmCanvas.tabInfo.key === 'arrangement';
    this.movable = hmCanvas.tabInfo.key === 'arrangement';
    this.selectable = hmCanvas.tabInfo.key === 'arrangement';
    this.removable = false;

  }

  waitToRender(firstTime = false) {
    return new Promise(resolver => {
      this.render(firstTime, resolver(this));
    });
  }

  render(firstTime = false, callback) {
    if (this.fabricElement) this.hmCanvas.fabricCanvas.remove(this.fabricElement);
    new fabric.loadSVGFromURL(`/img/pictograms/${this.pictogramName}.svg`, (objects, options) => {
      this.fabricElement = fabric.util.groupSVGElements(objects, options);
      this.fabricElement.set({
        fill: this.pictogramColor || 'red',
        left: this.data.pointCanvas.x,
        evented: this.movable,
        top: this.data.pointCanvas.y,
        angle: this.data.angle,
        originX: 'center',
        originY: 'center',
      });

      this.fabricElement.hmElement = this;
      this.fabricElement.scale(this.scalable(0.2));
      this.fabricElement
        .setControlVisible('tl', false)
        .setControlVisible('tr', false)
        .setControlVisible('br', false)
        .setControlVisible('bl', false)
        .setControlVisible('ml', false)
        .setControlVisible('mr', false)
        .setControlVisible('mb', false)
        .setControlVisible('mt', false)
        .setControlVisible('mtr', false);
      this.hmCanvas.fabricCanvas.add(this.fabricElement);
      if (callback) callback();
    });
    return this;
  }

  // update(params = {}, type = 'unknown') {
  //   for (let i in params) this.data[i] = params[i];
  //   return this;
  // }
}

export default Pictogram;
