import Vue from 'vue';
import Vuex from 'vuex';
import Api from "@/api";

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    eventBus: new Vue(),
    currentVersion: '1.1.5',
    userData: {
      user: {},
      signs: [],
      version: '',
      uploadFiles: {}
    },
    $api: new Api(),
    plan: {numerationInfo: {}},
    updateNumerationInfo(value, title) {
      console.log('updateNumerationInfo', {value, title});
      if (!this.plan.numerationInfo[title]) this.plan.numerationInfo[title] = {}
      this.plan.numerationInfo[title].disabled = value
      this.plan.numerationInfo = {...this.plan.numerationInfo};
      this.$api.request('project/numerationInfo/' + this.plan.project, {title, value});
    },
    polygonSettings: {
      zoneTypes: {1: 'Технический персонал', 2: 'Обслуживающий персонал', 3: 'Посетители'},
      colors: {
        1: '#B9E7B4',
        '0_1': '#8BD7A9',
        2: '#B6E5F1',
        '1_2': '#9B93C9',
        3: '#CDA8D2',
        basic: '#0000FF',
      },
    },
    connectionSettings: {
      elevator: {
        label: 'Лифт',
        color: '#22bf0c',
        dark_color: '#157c03',
      },
      ladder: {
        label: 'Лестница',
        color: '#129ad6',
        dark_color: '#0f6e87'
      },
    },
    lastSelectedSideTabIndex: {
      marking: 0,
      arrangement: 0,
    },
    pointTypes: ['door', 'not_room', 'connection'],
    arrangementTabs: ['arrangement', 'sign']
  },
  mutations: {
    setUserData(state, value) {
      state.userData = value;
    },
    setLastSelectedSideTabIndex(state, [tab, value]) {
      state.lastSelectedSideTabIndex[tab] = value;
    }
  }
});

export default store;

