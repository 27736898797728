import Vue from 'vue'
import App from './App.vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/ru-RU';
Vue.use(ElementUI, {locale});

import '@/assets/styles.scss';
import '@/assets/icons/style.css';
Number.prototype.mod = function(n) {
  return ((this % n) + n) % n;
}
Vue.config.productionTip = false
Vue.prototype.$api = new Api();

import globalMixins from "@/globalMixins";

import {router} from "@/router";
import store from "@/store";
import Api from "@/api";
new Vue({
  render: h => h(App),
  router,
  store,
  mixins: globalMixins,
}).$mount('#app')
