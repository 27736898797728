import globalMixins from "@/globalMixins";

class HMElement {
  constructor(hmCanvas, data = {}, options = {}) {
    this.hmCanvas = hmCanvas;
    this._prepareData(data);
    this.data = data;
    if (!this.data.plan) this.data.plan = hmCanvas.plan.id
    if (!this.data.id && !this.data._id) this.data._id = this._genID();
    this.options = options || {};
    this.rotatable = false;
    this.movable = false;
    this.shown = true;
    this.removable = true;
    this.selectable = true;
    this.rendered = false;
  }

  show(show = false) {
    if (!show && this.shown) {
      this.data = this._getData();
      this.hmCanvas.fabricCanvas.remove(this.fabricElement);
      this.shown = false;
    } else if (show && !this.shown) {
      this._prepareData(this.data);
      this.render();
      this.shown = true;
    }
    this.hmCanvas.fabricCanvas.requestRenderAll();
  }

  toCenter() {
    this.hmCanvas.setCenterTo(this.data.pointCanvas);
  }

  scalable(x) {
    return x * this.hmCanvas.project.scale / 100;
  }

  render(firstTime = false) {
    if (firstTime) this.rendered = true;
    else if (!this.rendered) return;
    let selectedID;
    if (this.fabricElement) {
      selectedID = this.hmCanvas.fabricCanvas.getActiveObject()?.hmElement?.data.id;
      this.hmCanvas.fabricCanvas.remove(this.fabricElement);
    }
    this.fabricElement = this.createElement();
    this.fabricElement.hmElement = this;
    this.hmCanvas.fabricCanvas.add(this.fabricElement);
    if (selectedID && selectedID == this.data.id) this.hmCanvas.fabricCanvas.setActiveObject(this.fabricElement);
    return this;
  }

  createElement() {

  }

  _genID() {
    let id = '_' + Date.now() + globalMixins.rand(1000, 9999);
    if (this.hmCanvas.elements.find(el => el._id === id)) return this._genID();
    return id;
    // return '_' + this.hmCanvas.plan.id + this.hmCanvas.elements.length + globalMixins.rand(1000 * 1000 * 1000, 9999999999);
  }

  select() {
    this.hmCanvas.fabricCanvas.setActiveObject(this.fabricElement);
    this.hmCanvas.fabricCanvas.requestRenderAll();
  }

  remove(forceRemove = false) {
    if (!this.removable && !forceRemove) return;
    this.hmCanvas.fabricCanvas.remove(this.fabricElement);
    let delIndex = this.hmCanvas.elements.findIndex(el => el.data._id == this.data._id);
    if (delIndex >= 0) this.hmCanvas.elements.splice(delIndex, 1);
    this.update({delete: 1});
  }

  onMove() {

  }

  _getData() {
    let data = globalMixins.clone(this.data);
    if (data.pointsCanvas) {
      if (this.fabricElement && this.fabricElement.points) data.pointsCanvas = this.fabricElement.points.map(point => ({
        x: point.x,
        y: point.y
      }));
      data.points = this._coordsToPercent(data.pointsCanvas);
      this.data.pointsCanvas = data.pointsCanvas;
    }

    if (data.pointCanvas) {
      if (this.fabricElement) data.pointCanvas = {
        // x: this.fabricElement.left,
        // y: this.fabricElement.top,
        x: this.fabricElement.left + (this.fabricElement.group ? this.fabricElement.group.left + this.fabricElement.group.width / 2 : 0),
        y: this.fabricElement.top + (this.fabricElement.group ? this.fabricElement.group.top + this.fabricElement.group.height / 2 : 0),
      };
      data.point = this._coordsToPercent([data.pointCanvas])[0];
      this.data.pointCanvas = data.pointCanvas;
    }
    if (this.rotatable && this.fabricElement) {
      data.angle = this.fabricElement.angle;
      this.data.angle = data.angle;
    }

    delete (data.pointsCanvas);
    delete (data.pointCanvas);
    return data;
  }

  _percentToCoords(points) {
    if (!this.hmCanvas.imageWidth || !this.hmCanvas.imageHeight) {
      this.hmCanvas.options.onCanvasError('Размер картинки не задан');
      throw 'Размер картинки не задан';
    }
    return points.map(({x, y}) => ({
      x: this.hmCanvas.imageWidth * x / 100,
      y: this.hmCanvas.imageHeight * y / 100
    }));
  }

  rotate(angle) {
    if (!this.rotatable) return;
    this.fabricElement.rotate(((this.fabricElement.angle || 0) + angle) % 360);
    this.update();
    this.hmCanvas.fabricCanvas.requestRenderAll();
  }

  move(diff) {
    if (!this.movable) return;
    this.data.pointCanvas.y += (diff.y || 0);
    this.data.pointCanvas.x += (diff.x || 0);
    this.fabricElement.set({
      top: this.fabricElement.top + (diff.y || 0),
      left: this.fabricElement.left + (diff.x || 0),
    });
    // this.hmCanvas.fabricCanvas.requestRenderAll();
    this.update();
    // this.render();
    this.onMove();
  }

  update(params = {}, type = 'unknown') {
    for (let i in params) this.data[i] = params[i];
    let data = this._getData();
    this.hmCanvas.requestsQueue.add(data);
    return this;
  }

  waitToRender(firstTime = false) {
    return new Promise(resolver => {
      resolver(this.render(firstTime));
    });
  }

  _prepareData(data) {
    if (data.points) data.pointsCanvas = this._percentToCoords(data.points);
    if (data.point) data.pointCanvas = this._percentToCoords([data.point])[0];
  }

  _coordsToPercent(points) {
    if (!this.hmCanvas.imageWidth || !this.hmCanvas.imageHeight) {
      this.hmCanvas.options.onCanvasError('Размер картинки не задан');
      throw 'Размер картинки не задан';
    }
    return points.map(({x, y}) => ({
      x: x / this.hmCanvas.imageWidth * 100,
      y: y / this.hmCanvas.imageHeight * 100
    }));
  }
}

export default HMElement;
