<template>
  <el-dialog :visible.sync="isVisible" title="Создание файла PDF" :close-on-press-escape="false"
             :close-on-click-modal="false"
             :before-close="handleClose">
    <div v-if="savedSvgPlans.length === project.plans.length" class="success-download">
      <div class="success-download-text">
        <i class="el-icon el-icon-check"></i>Расстановка.pdf успешно создался
      </div>
      <el-button @click="close">Ок</el-button>
    </div>
    <el-progress v-else :text-inside="true" :stroke-width="26"
                 :percentage="parseInt(percentage)"
                 color="var(--primary-color)"
                 :text-color="percentage ? '#FFF' : 'var(--primary-color)'"/>
  </el-dialog>
</template>

<script>
export default {
  name: "DownloadPlanDialog",
  props: ['savedSvgPlans'],
  data() {
    return {
      isVisible: false,
      project: {
        plans: []
      },
    }
  },
  computed: {
    percentage() {
      return this.savedSvgPlans.length ? (this.savedSvgPlans.length * 100 / (this.project.plans.length)).toFixed(0) : 0;
    }
  },
  methods: {
    open(params) {
      this.project = {};
      this.isVisible = true;
      this.project = params.project;
    },
    close() {
      this.isVisible = false;
    },
    download() {
      if (this.savedSvgPlans.length === this.project.plans.length) {
        this.$router.push(`/project/${this.project.id}?t=docs`);
        location.href = this.project.docs.find(item => item.name === 'Расстановка.pdf').link;
        this.close();
      }
    },
    handleClose(done) {
      if (this.savedSvgPlans.length !== this.project.plans.length)
        this.$confirm('Вы уверены, что хотите прервать скачивание?', {
          confirmButtonText: 'Да, прервать',
          cancelButtonText: 'Нет, продолжить'
        })
            .then(_ => {
              done();
              window.location.href = `/project/${this.project.id}?t=docs`
            })
            .catch(_ => {
            });
      else this.close();
    }
  },
  watch: {
    savedSvgPlans: function () {
      this.download();
    }
  }
}
</script>

<style scoped lang="scss">
.success-download {
  color: green;
  font-size: 18px;
  text-align: center;

  &-text {
    margin-bottom: 24px;
  }
}
</style>
