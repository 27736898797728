<template>
  <div class="page">
    <LoadingBlock v-if="loading"/>
    <AuthErrorBlock v-else-if="authError"/>
    <div v-else-if="currentTab === 'plans'" class="tab plans-tab">
      <template v-if="project.plans?.length">
        <div class="plans-list plans-list-header">
          <div class="plans-list-header-item">Файлы и их порядок</div>
          <div class="plans-list-header-item">Название для заголовка</div>
        </div>
        <div class="plans-list">
          <draggable v-model="project.plans" @change="onDraggableChange">
            <div v-for="(item, i) in project.plans" :key="i" class="plans-list-item">
              <div class="plans-list-item-filename">
                <i v-if="item.file" class="icon-menu" style="margin-right: 4px"/>
                <div class="plans-list-item-filename__text">{{ item.title }}</div>
              </div>
              <div class="plans-list-item-title-input">
                <template v-if="!item.file">Загрузка...</template>
                <el-input v-else v-model="item.description" placeholder="Название для заголовка"
                          @change="updateProject(project)"/>
              </div>
              <div class="plans-list-item-title-input">
                <el-input v-model="item.floor" placeholder="Этаж" @change="updateProject(project)"/>
              </div>
              <div v-if="item.file" class="plans-list-item-actions">
                <i class="el-icon el-icon-close" style="cursor: pointer" @click="openConfirmRemovePlanDialog(i)"/>
              </div>
            </div>
          </draggable>
        </div>
      </template>
      <div v-else class="plans-list-empty">
        Файлов нет, добавьте новые, чтобы начать проектировать
      </div>
      <el-button @click="addPlan" :loading="filesLoading">{{ filesLoading ? '' : '+ Добавить планы' }}</el-button>
      <input type="file" ref="file_input" accept="image/jpeg, image/jpg" multiple style="display: none"
             @change="onFileInputChange"/>
    </div>
    <div v-else-if="currentTab === 'settings'" class="tab settings-tab">
      <div class="setting-item">
        <div class="setting-item-label">Английский язык</div>
        <div class="setting-item-input">
          <el-checkbox v-model="project.eng" @change="updateProject(project)" :true-label="1" :false-label="0"/>
        </div>
      </div>
      <div class="setting-item-row">
        <div class="setting-item">
          <div class="setting-item-label">Нумерация</div>
          <div class="setting-item-input">
            <el-radio-group v-model="project.numeration_type" class="checkbox-group" @change="updateProject(project)">
              <el-radio :label="1">Трехзначная с учетом номера этажа</el-radio>
              <el-radio :label="2">Сквозная, начиная с 1</el-radio>
              <el-radio :label="3">Не нужна</el-radio>
            </el-radio-group>
          </div>
        </div>
        <el-checkbox v-model="project.numeration_staff" @change="updateProject(project)"
                     :false-label="0" :true-label="1" style="margin-top: 22px;">Включая служебные
        </el-checkbox>
      </div>
      <div class="setting-item">
        <div class="setting-item-label">Пользователи</div>
        <div class="setting-item-input">
          <el-checkbox-group v-model="project.zones" class="checkbox-group" @change="updateProject(project)">
            <el-checkbox v-for="(el, i) in $store.state.polygonSettings.zoneTypes" :key="i" :label="+i">{{ el }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
    </div>
    <div v-else-if="currentTab === 'docs'" class="docs-tab tab">
      <DocumentationList v-if="project?.docs?.length" :docs="project.docs" :project="project"/>
      <div v-else class="plans-list-empty">
        Документации нет
      </div>
    </div>
    <ConfirmDialog ref="confirm_remove_plan_dialog" title="Вы действительно хотите удалить План?"
                   confirm-button="Да, удалить" decline-button="Отмена"
                   @confirm="removePlan"/>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import LoadingBlock from "@/components/LoadingBlock";
import AuthErrorBlock from "@/components/AuthErrorBlock";
import DocumentationList from "@/components/DocumentationList";
import ConfirmDialog from "@/dialogs/ConfirmDialog";
import globalMixins from "@/globalMixins";

export default {
  name: "ProjectPage",
  components: {
    ConfirmDialog,
    DocumentationList,
    AuthErrorBlock,
    LoadingBlock,
    draggable,
  },
  data() {
    return {
      project: {},
      currentTab: 'plans',
      filesLoading: false,
      countFilesLoading: 0,
      loading: false,
      authError: false,
    }
  },
  methods: {
    getProject() {
      this.loading = true;
      this.$api.request(`project/get/${this.$route.params.id}`).then((data) => {
        this.project = data.response;
        this.loading = false;
        this.$store.state.eventBus.$emit('project', this.project);
      }).catch((data) => {
        this.loading = false;
        this.authError = data.error?.type === 'auth';
      })
    },
    updateProject(project) {
      this.$api.request(`project/update/${project.id}`, project).then((data) => {
        this.project = data.response;
        this.$store.state.eventBus.$emit('project', this.project);
      })
    },
    onDraggableChange() {
      this.updateProject(this.project);
    },
    addPlan() {
      this.$refs.file_input.click();
    },
    openConfirmRemovePlanDialog(i) {
      if (this.project.plans[i].elementsCount) {
        let title = this.project.plans[i].title;
        let subtitle = `На плане ${title} содержится разметка. При удалении плана разметка так же удалится. Если нужно обновить план, загрузите файл с таким же названием и размерами в px. Все равно удалить файл?`;
        this.$refs.confirm_remove_plan_dialog.open([i], `Вы действительно хотите удалить План ${title}?`,
            subtitle);
      } else this.removePlan([i]);
    },
    removePlan(indexes) {
      this.project.plans.splice(indexes[0], 1);
      this.updateProject(this.project);
      this.$refs.confirm_remove_plan_dialog.close();
    },
    onFileInputChange(e) {
      this.countFilesLoading = 0;
      let files = Array.from(e.target.files);
      files.forEach(file => {
        if (file.size > 1024 * 1024 * 20) {
          this.$store.state.eventBus.$emit('open-info-dialog', {
            title: 'Превышен размер файла',
            subtitle: 'Размер файла не должен превышать 20 мб'
          })
          return;
        }
        this.filesLoading = true;
        let formData = new FormData();
        formData.append('file', file, file.name);
        this.$api.request('plan/upload', formData, true).then((data) => {
          if (this.project.plans.some(plan => plan.title === data.title)) {
            let existIndex = this.project.plans.findIndex(plan => plan.title === data.title);
            this.project.plans[existIndex].file = data.response;
          } else {
            let parts = data.title.split('L');
            let floor = parseInt(parts[parts.length - 1]);
            // let floors = this.project.plans.map(plan=>plan.floor === '' ? 0 : plan.floor)
            //     .sort((a,b)=>a-b);
            // let insertPos = globalMixins.binarySearchClosest(floors, floor);
            this.project.plans.push({file: data.response, title: data.title, floor});
            this.project.plans = this.project.plans.sort((a, b)=>b.floor - a.floor);
          }
          this.countFilesLoading++;
          if (this.countFilesLoading === files.length) {
            this.filesLoading = false;
            this.updateProject(this.project);
            this.$refs.file_input.value = '';
          }
        }).catch(() => {
          this.filesLoading = false;
          this.updateProject(this.project);
          this.countFilesLoading = 0;
        })
      });
    },
  },
  mounted() {
    this.currentTab = this.$route.query.t || 'plans';
    this.getProject();
  },
  created() {
    this.$store.state.eventBus.$on('on-select-tab', (tab) => {
      this.currentTab = tab;
      this.$router.push({query: {t: tab}})
    });
    this.$store.state.eventBus.$on('on-project-changed', (project) => {
      this.updateProject(project);
    });
  },
  beforeDestroy() {
    this.$store.state.eventBus.$off('on-select-tab');
    this.$store.state.eventBus.$off('on-project-changed');
  }
}
</script>

<style scoped lang="scss">
.plans-list-header {
  margin-bottom: 12px;

  &-item {
    font-size: 12px;
  }
}

.plans-list-header, .plans-list-item {
  display: grid;
  grid-template-columns: 160px minmax(100px, 400px) 120px auto;
  grid-column-gap: 8px;
  align-items: center;
}

.plans-list-empty {
  padding: 24px 0;
  max-width: 280px;
  text-align: center;
  opacity: .5;
}

.plans-list {
  margin-bottom: 24px;
}

.plans-list-item {
  margin-bottom: 8px;

  &-filename {
    display: flex;
    align-items: center;
    cursor: grab;

    &__text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      direction: rtl;
    }

    .drag-icon {
      margin-right: 4px;
      min-width: 16px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.setting-item-row {
  display: flex;
  align-items: flex-start;
}

.setting-item {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  &-label {
    margin-bottom: 8px;
    font-size: 12px;
  }
}

.checkbox-group {
  display: flex;
  flex-direction: column;
}
</style>
