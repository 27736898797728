<template>
  <div class="page design-page">
    <div class="design-body">
      <div style="font-family: 'ABCFavoritPro';position: absolute;opacity: 0">Fontloader</div>
      <div class="canvas-content">
        <div v-if="loading" class="loading-block">
          <LoadingBlock/>
        </div>
        <div class="top-menu-container">
          <div class="top-menu-inner">
            <el-select @change="onSelectPlan"
                       v-model="selectedPlanId" class="small-input">
              <el-option v-for="(plan, i) in project.plans" :key="i" :value="plan.id" :label="plan.title"/>
            </el-select>
          </div>
        </div>
        <AuthErrorBlock v-if="authError"/>
        <canvas v-else style="width: 100%;height: 100%" ref="canvasElement"></canvas>
      </div>
      <div class="side-menu-container">
        <div class="side-menu-content">
          <div class="side-menu-canvas-header">
            <div v-if="currentDesignTab === 'marking'" class="side-menu-forms-title">
              {{ sideMenuTabs[currentSideTab].text }}
            </div>
            <div class="side-menu-canvas-actions">
              <el-tooltip content="V" placement="top">
                <el-button class="side-menu-canvas-actions-button"
                           @click="toggleMode('')"
                           :class="{'side-menu-canvas-actions-button__active': canvasMode === ''}">
                  <i class="icon-cursor"/>
                </el-button>
              </el-tooltip>
              <el-tooltip content="N" placement="top">
                <el-button v-if="!$store.state.arrangementTabs.includes(currentSideTab)" @click="toggleMode('create')"
                           class="side-menu-canvas-actions-button"
                           :class="{'side-menu-canvas-actions-button__active': canvasMode === 'create'}">
                  <i class="icon-plus"/>
                </el-button>
              </el-tooltip>
              <el-button v-if="['polygon', 'polyline'].includes(currentSideTab)" :disabled="!currentElement"
                         @click="toggleMode('addPoints')" class="side-menu-canvas-actions-button"
                         :class="{'side-menu-canvas-actions-button__active': canvasMode==='addPoints'}">
                <i class="icon-add_point"/>
              </el-button>
              <el-button v-if="['polygon', 'polyline'].includes(currentSideTab)" :disabled="!currentElement"
                         @click="toggleMode('removePoints')" class="side-menu-canvas-actions-button"
                         :class="{'side-menu-canvas-actions-button__active': canvasMode==='removePoints'}">
                <i class="icon-del_point"/>
              </el-button>
              <div v-if="$store.state.arrangementTabs.includes(currentSideTab)" class="sign-input-container">
                <div class="sign-input">
                  <el-input style="width: 160px" clearable v-model="filters.search" placeholder="Поиск ярлыка"
                            @input="signInputError = false">
                    <template #prefix>
                      <i class="icon-search"/>
                    </template>
                  </el-input>
                  <div v-if="signInputError" class="input-error sign-input-error">Элемент не найден</div>
                </div>
                <el-button v-if="filters.search.length"
                           @click="hmCanvas.searchElement(project.id, filters.search, 'arrangement', onElementFound)"
                           style="height: 32px; margin-left: 8px">Искать
                </el-button>
              </div>
            </div>
          </div>
          <div v-if="loading" class="side-menu-forms-list__empty">
            Загрузка...
          </div>
          <template v-else-if="!loading && !authError">
            <div class="side-menu-forms">
              <div v-if="currentDesignTab === 'arrangement'">
                <div v-if="!filters.door_id" class="side-menu-forms-title"
                     style="display: flex; align-items: center">
                  <i @click="toggleHideSigns('all')" :class="filters.hideSigns.all?'icon-hide':'icon-show'"
                     style="font-size: 16px; display: block; margin-right: 4px;cursor: pointer"/>
                  <span style="display: block">Типология</span>
                  <div v-if="isLocalhost" @click="regenArr" style="margin-left: 20px;
    font-size: 12px;
    background: #eee;
    padding: 5px 10px;cursor: pointer">Сгенерировать заново
                  </div>
                </div>
                <div class="side-menu-forms-list" style="margin-top: 20px">
                  <div v-if="filters.door_id" class="side-menu-forms-list__item">
                    <RoomForm :element="currentPlan.elements.find(el=>el.id==filters.door_id)"
                              :hm-canvas="hmCanvas" :requests-queue="hmCanvas.requestsQueue"
                              :project="project"/>
                  </div>
                  <div v-else
                       v-for="sign in arrangementSigns"
                       :key="sign" class="side-menu-forms-list__item"
                       style="border: none">
                    <div class="side-menu-forms-list__item__arrangement_title">
                      <i style="cursor: pointer"
                         @click="toggleHideSigns(sign)"
                         :class="filters.hideSigns[sign]?'icon-hide':'icon-show'"/>
                      <img
                          :style="genFilter($store.state.userData.signs[sign].color)"
                          :src="`/img/pictograms/${$store.state.userData.signs[sign].pictogram}.svg`"
                          alt=""
                          height="18">
                      {{ sign }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                  v-else-if="currentPlan.elements?.filter(el=>!sideMenuTabs[currentSideTab].onlyRender || sideMenuTabs[currentSideTab].onlyRender.includes(el.type)).length"
                  class="side-menu-forms-list">
                <draggable v-model="polylines"
                           :disabled="currentSideTab !== 'polyline'"
                           @end="onPolylineOrderChange">
                  <div
                      :ref="`form_${element._id}`"
                      v-for="(element) in currentPlan.elements.filter(el=>sideMenuTabs[currentSideTab].key===el.type).sort((a,b)=>a.order - b.order)"
                      @click="onFormHover(element)"
                      :key="element._id" class="side-menu-forms-list__item"
                      :style="`border-color: ${element.color ||
                    ( (selectedElement_Ids.includes(element._id) ) ? 'var(--primary-color)' : '#eee')}`"
                      :class="{'side-menu-forms-list__item__active': selectedElement_Ids.includes(element._id),
                    'side-menu-forms-list__item__arrangement': element.type === 'arrangement'}">
                    <i v-if="element.type !== 'arrangement'"
                       @click="openConfirmRemoveElement([element._id])"
                       class="el-icon el-icon-close remove-side-item-button"/>
                    <template v-if="currentSideTab === 'polygon'">
                      <div class="side-menu-forms-list__item_users">
                        <div class="side-menu-forms-list__item__label">Зона доступа</div>
                        <div class="side-menu-forms-list__item_users_checkbox">
                          <el-select v-model="elementWrapper(element).zoneType"
                                     @change="val=>onSelectPolygonUsers(element,val)">
                            <template v-for="(el, i) in $store.state.polygonSettings.zoneTypes">
                              <el-option
                                  :key="i"
                                  v-if="project.zones.includes(+i)"
                                  :value="+i"
                                  :label="el">{{ el }}
                              </el-option>
                            </template>
                          </el-select>
                        </div>
                      </div>
                    </template>
                    <template v-else-if="currentSideTab === 'connection'">
                      <RoomZones :zones="element.zones"/>
                      <div class="side-menu-forms-list__item_row">
                        <div class="side-menu-forms-list__item_col" style="max-width: 100px">
                          <div class="side-menu-forms-list__item__label">Тип связи</div>
                          <el-select v-model="element.connection_type" @change="v=>onChangeConnectionType(v, element)">
                            <el-option v-for="(val, key) in $store.state.connectionSettings" :key="key"
                                       :value="key" :label="val.label">
                              <i :class="key==='elevator'?'icon-elevator':'icon-stairs'"/>
                              {{ val.label }}
                            </el-option>
                          </el-select>
                        </div>
                        <div class="side-menu-forms-list__item_col">
                          <div class="side-menu-forms-list__item__label">Обозначение</div>
                          <el-input v-model="element.title"
                                    :ref="`title_input_${element._id}`"
                                    @input="hmCanvas.element(element._id).update({title: element.title}).render();"/>
                        </div>
                      </div>
                      <div class="side-menu-forms-list__item_row">
                        <el-checkbox v-model="element.staff" :true-label="1"
                                     :false-label="0"
                                     @change="v=>onChangeConnectionStaff(v, element)">Служебное
                        </el-checkbox>
                      </div>
                      <div class="side-menu-forms-list__item_col">
                        <div class="side-menu-forms-list__item__label">Тип носителя</div>
                        <el-select v-model="element.signs" multiple allow-create filterable
                                   placeholder="Без маркировки"
                                   @change="hmCanvas.element(element._id).update({signs: element.signs})"
                                   :no-data-text="'  '">
                          <el-option v-for="(option, i) in element.allSigns" :key="i" :value="option"
                                     :label="option"/>
                        </el-select>
                      </div>
                    </template>
                    <template v-else-if="['door', 'not_room'].includes(currentSideTab)">
                      <RoomForm :project="project" :ref="`title_input_${element._id}`" :element="element"
                                :hm-canvas="hmCanvas"/>
                    </template>
                    <template v-else-if="currentSideTab === 'polyline'">
                      <div class="side-menu-forms-list__item_col">
                        <div class="side-menu-forms-list__item__label">Линия {{ element.order }}</div>
                      </div>
                    </template>
                  </div>
                </draggable>

              </div>
              <div v-else class="side-menu-forms-list__empty">
                Элементов нет
              </div>
            </div>
          </template>
        </div>
        <div class="side-menu-tabs">
          <el-button v-for="(menuItem, key) in filteredSideTabs"
                     :key="key" :disabled="tooltipDisabled[key] || loading"
                     @mouseleave="tooltipDisabled[key] = true"
                     @mouseenter="tooltipDisabled[key] = false"
                     @click="setPlan(currentPlan.id, key)"
                     class="side-menu-tabs__tab"
                     :class="{'side-menu-tabs__tab__active': currentSideTab === key}">
            <i :class="`icon-${menuItem.icon}`"/>
          </el-button>
        </div>
      </div>
    </div>
    <ConfirmDialog ref="confirm_remove_element_dialog" title="Вы действительно хотите удалить элемент?"
                   confirm-button="Да, удалить" decline-button="Отмена"
                   @confirm="removeSideItem"/>
    <DownloadPlanDialog ref="download_plan_dialog" :project="project" :savedSvgPlans="savedSvgPlans"/>
  </div>
</template>
<script>
import LoadingBlock from "@/components/LoadingBlock";
import AuthErrorBlock from "@/components/AuthErrorBlock";
import ConfirmDialog from "@/dialogs/ConfirmDialog";
import draggable from 'vuedraggable';
import HMCanvas from "@/pages/project/canvas_methods/HMCanvas";
import RoomZones from "@/components/RoomZones";
import genFilter from "@/pages/project/canvas_methods/algorithms/color";
import RoomForm from "@/components/RoomForm";
import globalMixins from "@/globalMixins";
import DownloadPlanDialog from "@/dialogs/DownloadPlanDialog";

export default {
  name: 'ProjectDesignPage',
  components: {DownloadPlanDialog, RoomForm, RoomZones, ConfirmDialog, AuthErrorBlock, LoadingBlock, draggable},
  data() {
    return {
      project: {},
      tooltipDisabled: {},
      currentPlan: {},
      polylines: [],
      currentElement: null,
      canvas: null,
      canvasMode: '',
      currentSideTab: 'polygon',
      currentDesignTab: 'marking',
      selectedPlanId: null,
      sideMenuTabs: {
        polygon: {
          icon: 'zones_key',
          text: 'Зоны доступа',
          types: ['polygon'],
          designTab: 'marking',
          key: 'polygon',
        },
        connection: {
          icon: 'trans_points',
          text: 'Связи планов',
          types: ['connection', 'polygon'],
          designTab: 'marking',
          key: 'connection',
          onlyRender: ['connection']
        },
        door: {
          icon: 'doors',
          text: 'Помещения',
          types: ['door', 'polygon', 'polyline', 'not_room'],
          designTab: 'marking',
          key: 'door',
          onlyRender: ['door']
        },
        not_room: {
          icon: 'locations',
          text: 'Не помещения',
          types: ['not_room', 'polygon', 'polyline', 'door'],
          designTab: 'marking',
          key: 'not_room',
          onlyRender: ['not_room'],
        },
        polyline: {
          icon: 'order_num',
          text: 'Порядок нумерации',
          types: ['polyline', 'door', 'not_room', 'polygon'],
          onlyRender: ['polyline', 'door', 'not_room'],
          designTab: 'marking',
          key: 'polyline',
        },
        arrangement: {
          icon: 'deploy',
          text: 'Расстановка',
          types: ['arrangement', 'door', 'connection', 'not_room'],
          designTab: 'arrangement',
          onlyRender: ['arrangement'],
          key: 'arrangement',
        },
        sign: {
          icon: 'labels',
          text: 'Расположение ярлычков',
          types: ['arrangement', 'door', 'sign', 'connection', 'not_room'],
          designTab: 'arrangement',
          onlyRender: ['arrangement', 'sign'],
          key: 'sign',
        },
      },
      filters: {
        hideSigns: {},
        door_id: null,
        search: '',
      },
      selectedElement_Ids: [],
      sideItems: {
        polygon: [{users: ['Посетители']}, {users: ['Посетители', 'Обслуживающий персонал']}],
        connection: [{connection_type: 'ladder', title: 'Л1', staff: 0,}],
        door: [{explication: 'Электрощитовая'}],
        not_room: [],
        order: []
      },
      loading: false,
      authError: false,
      savedSvgPlans: [],
      isDownloadPlan: false,
      signInputError: false
    };
  },
  beforeMount() {
    if (this.$route.params.elementType) {
      this.currentSideTab = this.$route.params.elementType;
      if (this.$store.state.arrangementTabs.includes(this.$route.params.elementType)) {
        this.currentDesignTab = 'arrangement';
      }
    }
  },
  mounted() {
    // setTimeout(() => {
    this.isDownloadPlan = this.$route.query.download === '1';
    this.init();
    document.addEventListener('keydown', this.shortcuts);
    let timeout = null;
    window.onresize = () => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => this.init(), 300);
    }
    // }, 1000);
  },
  computed: {
    filteredSideTabs() {
      return Object.fromEntries(
          Object.entries(this.sideMenuTabs).filter(([key, value]) => value.designTab === this.currentDesignTab)
      );
    },
    arrangementSigns() {
      let val = this.currentPlan?.elements?.filter(el => el.type === 'arrangement').map(el => el.sign).filter((value, index, array) => array.indexOf(value) === index).sort()
      return val || [];
    },
    storeState() {
      return this.$store.state;
    },
    isLocalhost() {
      return location.host.indexOf('localhost') > -1
    },
  },
  methods: {
    onSelectPlan(plan_id) {
      this.setPlan(plan_id);
    },
    regenArr() {
      this.$api.request('element/clearArrangement', {
        plan: this.currentPlan.id,
        tab: this.currentSideTab
      }).then(() => window.location.reload());
    },
    toggleHideSigns(sign) {
      if (sign === 'all') {
        if (this.filters.hideSigns[sign]) this.filters.hideSigns = {};
        else {
          this.filters.hideSigns[sign] = 1;
          this.arrangementSigns.forEach(sign => this.filters.hideSigns[sign] = 1);
        }
      } else {
        if (this.filters.hideSigns[sign]) {
          delete this.filters.hideSigns[sign]
          if (Object.keys(this.filters.hideSigns).length === 1) delete this.filters.hideSigns.all
        } else {
          this.filters.hideSigns[sign] = 1;
          this.filters.hideSigns.all = 1;
        }
      }
      this.filters.hideSigns = {...this.filters.hideSigns};
      this.hmCanvas.setFilters(this.filters);
    },
    genFilter: genFilter,
    onFormHover(element) {
      this.hmCanvas.element(element._id).select();
    },
    toggleMode(mode, forceSet) {
      this.hmCanvas.toggleMode(mode, forceSet);
    },
    init() {
      let width = this.$refs.canvasElement.offsetWidth;
      let height = this.$refs.canvasElement.offsetHeight;
      if (this.hmCanvas) this.hmCanvas.reset();
      this.$refs.canvasElement.width = width;
      this.$refs.canvasElement.height = height;
      let that = this;
      window.mainComp = this;
      this.hmCanvas = new HMCanvas(this.$refs.canvasElement, {
        width, height,
        onModeChanged: mode => that.canvasMode = mode,
        onUpdateElement: element => {
          let index = this.currentPlan.elements.findIndex(el => el._id === element._id);
          if (index >= 0) {
            if (element.delete) {
              this.currentPlan.elements.splice(index, 1);
            } else this.currentPlan.elements[index] = element;
          } else {
            element.type !== 'polyline' ? this.currentPlan.elements.unshift(element) : this.currentPlan.elements.push(element);
          }
          this.currentPlan.elements = this.currentPlan.elements.slice()
        },
        onElementSelected: element => {
          if (!element) {
            that.currentElement = null;
            that.selectedElement_Ids = [];
            return;
          }
          if (element.hmElement?.data) {
            that.currentElement = element?.hmElement?.data;
            that.selectedElement_Ids = [that.currentElement._id];
          } else if (element._objects?.length) {
            that.currentElement = element._objects.filter(e => e.hmElement).sort((a, b) => b.hmElement.data.id - a.hmElement.data.id)[0].hmElement.data
            that.selectedElement_Ids = element._objects.filter(e => e.hmElement).map(el => el.hmElement?.data?._id);
          } else return;
          if (that.currentElement && that.currentElement._id && that.$refs[`form_${that.currentElement._id}`])
            that.$refs[`form_${that.currentElement._id}`][0].scrollIntoView({behavior: 'smooth', block: 'center'});
          that.$forceUpdate();
        },
        onElementCreated: element => {
          // that.getElementsFromCanvas();
          setTimeout(() => {
            if (this.$store.state.pointTypes.includes(element.data.type)) {
              let ref = that.$refs[`form_${element.data._id}`];
              if (ref) ref[0].querySelector('.el-input__inner').focus();
              else that.$refs[`title_input_${element.data._id}`][0].focus();
            }
            element.select();
            // this.currentPlan.elements.push(element);
          });
        },
        onElementsChanged() {
        },
        onLoaded: () => {
          that.loading = false;
        },
        onCanvasError: error => {
          that.loading = false;
          that.$store.state.eventBus.$emit('open-info-dialog', {title: error})
        },
        onSvgSaved: () => {
          if (this.isDownloadPlan) {
            this.savedSvgPlans.push(this.currentPlan.id);
            let currentPlanIndex = this.project.plans.findIndex(plan => +plan.id === +this.currentPlan.id);
            if (this.savedSvgPlans.length === this.project.plans.length) return;
            this.setPlan(this.getNextPlan(currentPlanIndex));
          }
        }
      });
      this.loadProject();
    },
    getElementsFromCanvas() {
      this.hmCanvas.elements.forEach(el => {
        let index = this.currentPlan.elements.findIndex(e => e._id === el.data._id);
        let data = el._getData();
        if (index === -1) this.currentPlan.elements.unshift(data);
        else this.currentPlan.elements[index] = data;
      });
      let toRemove = [];
      this.currentPlan.elements.forEach((el, i) => {
        if ((!this.sideMenuTabs[this.currentSideTab].onlyRender || this.sideMenuTabs[this.currentSideTab].onlyRender.includes(el.type)) && !this.hmCanvas.elements.find(e => e.data._id === el._id))
          toRemove.push(el._id);
      });
      toRemove.forEach(_id => this.currentPlan.elements.splice(this.currentPlan.elements.findIndex(e => e._id === _id), 1));
      this.$forceUpdate();
    },
    loadProject() {
      this.loading = true;
      this.$api.request(`project/get/${this.$route.params.id}`).then(data => {
        this.authError = false;
        this.project = data.response;
        this.project.scale = this.project.scale || 100;
        this.setPlan(this.$route.params.plan_id, this.$route.params.elementType || 'polygon');
        this.$store.state.eventBus.$emit('project', this.project);
        this.openDownloadPlanDialog({project: this.project});
      }).catch((data) => {
        this.loading = false;
        this.authError = data.error?.type === 'auth';
      })
    },
    openDownloadPlanDialog(params) {
      if (this.isDownloadPlan) this.$refs.download_plan_dialog.open(params);
    },
    elementWrapper(element) {
      if (!element.color) element.color = this.$store.state.polygonSettings.colors.basic;
      return element;
    },
    setPlan(plan_id, elementType = '', selectElements = []) {
      if (this.filters.door_id) {
        this.filters.door_id = null;
        this.hmCanvas.setFilters(this.filters);
      }
      if (elementType) {
        this.$store.commit('setLastSelectedSideTabIndex',
            [this.filteredSideTabs[elementType].designTab, Object.keys(this.filteredSideTabs).indexOf(elementType)])
        this.currentSideTab = elementType;
        this.hmCanvas.state.elementType = elementType;
        this.toggleMode('', true);
      }
      this.$api.request(`plan/get/${plan_id}`, {types: this.sideMenuTabs[this.currentSideTab].types}).then(async (data) => {
        this.authError = false;
        this.selectedPlanId = +plan_id;
        let params = {id: this.$route.params.id, plan_id, elementType: this.currentSideTab};
        if (JSON.stringify(params) !== JSON.stringify(this.$route.params)) {
          this.$router.push({
            name: 'ProjectDesignPage',
            params
          }).catch(() => {
          });
        }
        this.currentPlan = data.response;
        this.hmCanvas.setPlan(this.currentPlan, this.project, this.sideMenuTabs[this.currentSideTab], () => {
          setTimeout(() => {
            if (selectElements.length) {
              let elements = this.currentPlan.elements.filter(el => selectElements.includes(el.id));
              this.filters.door_id = elements[0].door_id;
              this.hmCanvas.setFilters(this.filters);
              this.hmCanvas.element(elements[0].id).toCenter();
            }
          }, 100);
        });
      }).catch((data) => {
        this.loading = false;
        this.authError = data.error?.type === 'auth';
      });
    },
    openConfirmRemoveElement(ids) {
      if (ids.length) this.$refs.confirm_remove_element_dialog.open(ids);
    },
    removeSideItem(elementIds) {
      elementIds.forEach(id => this.hmCanvas.element(id)?.remove());
      this.hmCanvas.fabricCanvas.discardActiveObject().requestRenderAll();
      this.$refs.confirm_remove_element_dialog.close();
      // this.getElementsFromCanvas();
      this.hmCanvas.toggleMode('', true);
    },
    onSelectPolygonUsers(element, val) {
      element.color = this.detectPolygonColor(val);
      this.hmCanvas.element(element.id).update({color: element.color, zoneType: element.zoneType}).render();
    },
    shortcuts(e) {
      if (e.target.nodeName === 'INPUT') return;
      let rotations = {
        KeyE: 1,
        KeyQ: -1,
        KeyEShift: 15,
        KeyQShift: -15,
      }
      let smallMove = 1;
      let bigMove = 10;
      let movingElement = {
        ArrowLeft: {x: -smallMove},
        ArrowLeftShift: {x: -bigMove},
        ArrowRight: {x: smallMove},
        ArrowRightShift: {x: bigMove},
        ArrowUp: {y: -smallMove},
        ArrowUpShift: {y: -bigMove},
        ArrowDown: {y: smallMove},
        ArrowDownShift: {y: bigMove},
        KeyA: {x: -smallMove},
        KeyAShift: {x: -bigMove},
        KeyD: {x: smallMove},
        KeyDShift: {x: bigMove},
        KeyW: {y: -smallMove},
        KeyWShift: {y: -bigMove},
        KeyS: {y: smallMove},
        KeySShift: {y: bigMove},
      }
      let currentPlanIndex = this.project.plans.findIndex(plan => +plan.id === +this.currentPlan.id);
      let code = e.code + (e.shiftKey ? 'Shift' : '');
      if (e.code === 'KeyV') this.toggleMode('', true);
      else if (e.code === 'KeyN') this.toggleMode('create', true);
      else if (this.currentElement && ['polygon', 'polyline'].includes(this.currentSideTab) && e.keyCode === 187) this.toggleMode('addPoints');
      else if (this.currentElement && ['polygon', 'polyline'].includes(this.currentSideTab) && e.keyCode === 189) this.toggleMode('removePoints');
      else if (e.code === 'KeyI') {
        if (this.filters.door_id) {
          this.filters.door_id = null;
        } else {
          let object = this.hmCanvas.fabricCanvas.getActiveObject()?.hmElement?.data;
          if (object && ['sign', 'arrangement'].includes(object.type)) this.filters.door_id = object.door_id;
        }
        this.hmCanvas.setFilters(this.filters);
      } else if (e.code === 'Escape') {
        if (this.filters.door_id) {
          this.filters.door_id = null;
          this.hmCanvas.setFilters(this.filters);
        }
      } else if (rotations[code]) {
        this.hmCanvas.fabricCanvas.getActiveObjects().forEach(obj => obj.hmElement?.rotate(rotations[code]))
      } else if ((e.ctrlKey || e.metaKey) && ['ArrowUp', 'ArrowDown'].includes(e.code)) {
        this.setPlan(this.getNextPlan(currentPlanIndex, e));
      } else if (movingElement[code]) {
        let diff = movingElement[code];
        let object = this.hmCanvas.fabricCanvas.getActiveObject();
        if (!object || !object._objects && !object.hmElement) return;
        if (object._objects) {
          object.set({
            top: object.top + (diff.y || 0),
            left: object.left + (diff.x || 0),
          });
          object._objects.forEach(o => o.hmElement?.update());
          this.hmCanvas.fabricCanvas.requestRenderAll();
        } else if (object.hmElement && object.hmElement.move) object.hmElement.move(diff)
      } else if (e.code === 'Backspace') {
        this.openConfirmRemoveElement(this.hmCanvas.fabricCanvas.getActiveObjects().filter(el => el.hmElement && el.hmElement.data && el.hmElement.data.type === this.sideMenuTabs[this.currentSideTab].key && el.hmElement.removable).map(el => el.hmElement.data._id));
      }
    },
    getNextPlan(currentPlanIndex, e = null) {
      let nextOrPrevPlanIndex = 1;
      if (e) nextOrPrevPlanIndex = e.code === 'ArrowUp' ? -1 : 1;
      return this.project.plans[(currentPlanIndex + nextOrPrevPlanIndex).mod(this.project.plans.length)].id;
    },
    detectPolygonColor(val) {
      return this.$store.state.polygonSettings.colors[val];
    },
    updateProject(project) {
      this.$api.request(`project/update/${this.project.id}`, project).then(() => {
        this.init();
      })
    },
    searchExplication(q, cb) {
      this.$api.request('element/searchExplication', {q}).then((data) => {
        cb(data.response.map(el => {
          return {
            value: el.name,
            ...el,
          }
        }));
      })
    },
    onExplicationSelect(explication, element) {
      let updateFields = ['eng', 'rus'];
      element.title = explication.name;
      element.explication = explication;
      element.optionsSigns = explication.signs;
      if (element.optionsSigns.length) {
        element.signs = element.optionsSigns.slice(0, element.connection_type === 'elevator' ? 2 : 1);
      }
      updateFields.forEach(field => element[field] = explication[field]);
      this.hmCanvas.element(element._id).update(element).render();
      // this.updateElements([element]);
    },
    onPolylineOrderChange() {
      this.polylines.forEach((el, i) => {
        el.order = i + 1;
        this.hmCanvas.element(el._id).update({order: el.order}).render();
      });
      this.hmCanvas.updatePointNumbers();
    },
    prepareSignsForConnection(element) {
      let zoneForAll = (element.zones[0] === 3);
      let key;
      if (zoneForAll) key = element.staff ? 1 : 0;
      else key = element.staff ? 0 : -1;
      let signKey;
      let length;
      if (zoneForAll && !element.staff) {
        signKey = element.connection_type + '0';
        length = 2;
      } else if (zoneForAll && element.staff) {
        signKey = '1';
        length = 1;
      } else if (!zoneForAll && !element.staff) {
        element.allSigns = [];
        return [];
      } else if (!zoneForAll && element.staff) {
        signKey = element.connection_type + '1';
        length = 2;
      }
      element.allSigns = this.$store.state.userData.explications[signKey]?.signs;
      // console.log({zones: element.zones, zoneForAll, signKey, all: element.allSigns, length})
      if (!element.allSigns) return [];
      return element.allSigns.slice(0, length);
      // return element.allSigns.slice(0, (key === 0) ? 1 : 2);
      // if (+element.staff && element.zones && element.zones[0] && element.zones[0] === 1) {
      //   return element.allSigns.slice(2, 4);
      // } else if (element.connection_type === 'ladder') return element.allSigns.slice(0, 1);
      // else return element.allSigns.slice(0, 2);
    },
    onChangeConnectionType(v, element) {
      let color = this.$store.state.connectionSettings[v][element.staff ? 'dark_color' : 'color'];
      let signs = this.prepareSignsForConnection(element);
      this.hmCanvas.element(element._id).update({
        color,
        connection_type: v,
        signs,
        allSigns: element.allSigns
      }, 'onChangeConnectionType').render();
    },
    onChangeConnectionStaff(v, element) {
      let color = this.$store.state.connectionSettings[element.connection_type][v ? 'dark_color' : 'color'];
      let signs = this.prepareSignsForConnection(element);
      this.hmCanvas.element(element._id).update({
        color,
        staff: v,
        signs,
        allSigns: element.allSigns
      }, 'onChangeConnectionStaff').render();
    },
    onElementFound(data) {
      if (data.plan && data.id) {
        this.$notify({
          type: 'success',
          message: `Элемент найден на плане: ${data.plan}`
        })
        this.setPlan(data.plan, '', [data.id]);
      } else this.signInputError = true;
    },
  },
  created() {
    this.$store.state.eventBus.$on('on-select-design-tab', tab => {
      this.currentDesignTab = tab;
      let lastSelectedTabIndex = this.storeState.lastSelectedSideTabIndex[tab];
      this.currentSideTab = Object.keys(this.filteredSideTabs)[lastSelectedTabIndex];
      this.setPlan(this.currentPlan.id, this.currentSideTab);
    });
    this.$store.state.eventBus.$on('on-project-scale-changed', (project) => {
      this.updateProject(project);
    });
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.shortcuts);
    this.$store.state.eventBus.$off('on-select-design-tab');
    this.$store.state.eventBus.$off('on-project-scale-changed');
  },
  watch: {
    'currentPlan.elements'() {
      this.polylines = this.currentPlan.elements.filter(el => el.type === 'polyline');
      this.polylines.sort((a, b) => a.order - b.order);
    }
  }
};
</script>

<style lang="scss">
@font-face {
  font-family: ABCFavoritPro;
  src: url("/fonts/ABCFavoritPro-Regular.woff2") format("woff2");
}

.design-page {
  padding: 0;
  position: relative;
  max-width: 100%;
}

.top-menu-container {
  position: absolute;
  left: 0;
  top: 24px;
  z-index: 1;
  height: fit-content;
  width: fit-content;
  margin: 0 var(--hor-padding);
}

.top-menu-inner {
  display: flex;
  column-gap: 8px;
  max-width: var(--desktop-max-width);
}

.zoom-button {
  height: 24px;
  width: 24px;
  margin-left: 0;
  padding: 0;
}

.design-body {
  height: calc(100vh - 71px);
  display: grid;
  grid-template-columns: 3fr 400px;

  .canvas-content {
    position: relative;

    .loading-block {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      inset: 0;
      background: rgba(255, 255, 255, .4);
      z-index: 9999;
    }
  }

  .side-menu-container {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 64px;
    border-left: 1px solid var(--primary-color);
    border-bottom: 1px solid var(--primary-color);

    .side-menu-content {
      padding: 0 8px 24px;
      max-height: calc(100vh - 72px);
      overflow: auto;

      .side-menu-canvas-header {
        width: 100%;
        position: sticky;
        top: 0;
        z-index: 999;
        background: #FFFFFF;
        padding: 16px 0 0;
        margin-bottom: 8px;
        box-shadow: 0 0 10px 10px white;
      }

      .side-menu-canvas-actions {
        display: flex;
        padding: 8px 0;
        column-gap: 8px;

        input {
          height: 32px;
        }

        &-button {
          height: 32px;
          width: 32px;
          min-width: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          margin-left: 0;
          font-size: 20px;

          &__active {
            background: var(--primary-color);
            color: #FFFFFF;
          }
        }
      }
    }

    .side-menu-tabs {
      border-left: 1px solid var(--primary-color);

      &__tab {
        width: 100%;
        height: 64px;
        border: none;
        border-bottom: 1px solid var(--primary-color);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 32px;
        margin: 0;
        padding: 0 10px;

        &__active {
          background: var(--primary-color);
          color: #FFFFFF;
        }
      }
    }
  }

  .remove-side-item-button {
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 8px;
  }

  .side-menu-forms {
    &-title {
      font-size: 16px;
      font-weight: 500;
    }

    &-list {
      &__empty {
        width: 100%;
        padding: 8px 0;
        text-align: center;
        opacity: .6;
      }

      &__item {
        position: relative;
        margin-bottom: 8px;
        padding: 8px;
        border: 2px solid #eee;

        &__arrangement {
          border: none;
          box-shadow: none;
          padding-left: 0;

          &_title {
            display: flex;
            align-items: flex-end;
            column-gap: 8px;
          }
        }

        &__active {
          border: 2px solid var(--primary-color);
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.6);
        }

        &__label {
          width: 100%;
          text-align: left;
          margin-bottom: 4px;
          font-size: 12px;
        }

        &_number {
          margin-bottom: 8px;
        }

        &_col {
          margin-bottom: 8px;
        }

        &_row {
          display: flex;
          column-gap: 8px;
          margin-bottom: 8px;

          .side-menu-forms-list__item_col {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.sign-input-container {
  display: flex;
  position: relative;
  margin-bottom: 16px;

  .sign-input-error {
    position: absolute;
    bottom: -16px;
  }
}
</style>
