<template>
<el-dialog :visible.sync="isVisible" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
  <el-form ref="form" :model="formData" label-position="top">
    <el-form-item label="Логин">
      <el-input v-model="formData.login" placeholder="Логин"/>
    </el-form-item>
    <el-form-item label="Пароль" :error="passError">
      <el-input v-model="formData.password" @input="passError = ''" placeholder="Пароль" type="password" clearable/>
    </el-form-item>
    <el-button @click="login" :loading="loading">{{ loading ? '' : 'Войти' }}</el-button>
  </el-form>
</el-dialog>
</template>

<script>
export default {
  name: "AuthDialog",
  emits: ['on-success-auth'],
  data() {
    return {
      isVisible: false,
      formData: {login: '', password: ''},
      rules: {
        login: [
          {required: true, message: 'Укажите логин', trigger: 'change'},
          {required: true, message: 'Укажите логин', trigger: 'blur'},
        ],
        password: [
          {required: true, message: 'Укажите пароль', trigger: 'change', type: 'password'},
          {required: true, message: 'Укажите пароль', trigger: 'blur', type: 'password'},
        ]
      },
      loading: false,
      passError: '',
    }
  },
  methods: {
    open() {
      this.isVisible = true;
    },
    login() {
      this.$refs.form.validate(isValid => {
        if (isValid) {
          this.loading = true;
          this.$api.request('auth/login', this.formData).then((data)=>{
            // this.loading = false;
            // this.isVisible = false;
            // this.$emit('on-success-auth');
            window.location.reload();
          }).catch((data)=>{
            if (data.error?.type === 'password') this.passError = data.error.text;
            this.loading = false;
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
