export default {
  clone(obj) {
    if (obj === null || typeof obj !== "object") return obj;
    let copy = Array.isArray(obj) ? [] : {};
    for (let i in obj) copy[i] = this.clone(obj[i]);
    return copy;
  },
  debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), timeout);
    }
  },
  rand(min, max) {
    return Math.floor(Math.random() * (max - min+1)) + min;
  },
  convertVersion(version) {
    if (!version) return 0;
    let versionCells = version.split('.');
    versionCells = versionCells.map((i) => parseInt(i));
    return versionCells[0] * 100000 + versionCells[1] * 1000 + versionCells[2];
  }
}
