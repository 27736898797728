import store from '@/store';

export default class Api {
    url = 'https://machina.khromov.su/api/';
    request(method, data = {}, isUpload = false) {
        data.version = store.state.currentVersion;
        let formData = JSON.stringify( data);
        return fetch(`${this.url}${method}`, {
            method: "POST",
            body: isUpload ? data : formData,
            credentials: "include",
        })
            .then(response => {
                if (!response.ok) throw new Error(response.statusText)
                return response.json();
            })
            .then(data => {
                if (data.success) return data;
                if ( !['auth', 'password'].includes(data.error?.type || data.error))
                    store.state.eventBus.$emit('open-info-dialog', {title: data.error?.text || data.error});
                return Promise.reject(data);
            })
    }
}
