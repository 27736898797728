<template>
  <div class="loading-container">
    <div class="el-icon el-icon-loading"></div>
  </div>
</template>

<script>

export default {
  name: 'LoadingBlock',
  mounted() {
    // new Rive({
    //   canvas: this.$refs.canvas,
    //   src: '/rive/loading.riv',
    //   layout: new Layout({
    //     fit: 'fitHeight',
    //     // alignment: this.$props.alignment,
    //   }),
    //   autoplay: true
    // })
  }
}
</script>

<style scoped lang="scss">
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
</style>
