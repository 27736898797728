import {fabric} from "fabric";
import store from "@/store";
import HMElement from "@/pages/project/canvas_methods/elements/HMElement";
import geometryFunctions from "@/pages/project/canvas_methods/algorithms/geometryFunctions";
import globalMixins from "@/globalMixins";

class Point extends HMElement {
  constructor(hmCanvas, data = {}, options = {}) {
    super(hmCanvas, data, options);
    this.colors = {door: '#e59200', connection: '#CA4665', not_room: '#3f382e', service_room: '#9b6002'};
    this.movable = true;
  }

  getColor() {
    if (this.data.type==='connection') {
      return this.data.color || this.colors[this.data.type];
    } else {
      return (this.data.zones?.some(v => [1, 2].includes(v)) ||
        ['Служебное', 'Техническое'].includes(this.data.explication?.zone)) ?
        this.colors.service_room :
        (this.data.color || this.colors[this.data.type]);
    }
  }

  createElement() {
    let color = this.getColor();
    return new fabric.Circle({
      radius: 5 * (this.hmCanvas.project.scale / 100),
      top: this.data.pointCanvas.y,
      left: this.data.pointCanvas.x,
      fill: color,
      originX: 'center',
      originY: 'center',
      evented: typeof this.options.evented !== 'undefined' ? this.options.evented : true,
      hasControls: false,
      borderColor: color,
      borderWidth: 3,
      borderScaleFactor: 2,
      padding: 3 * (this.hmCanvas.project.scale / 100)
    });
  }

  render(firstTime = false) {
    super.render(firstTime);
    if (firstTime || this.rendered) this.renderTextBox();
    if (firstTime) this.updatePointDependencies();
    return this;
  }

  renderTextBox() {
    let color = this.getColor();
    if (this.fabricText) this.hmCanvas.fabricCanvas.remove(this.fabricText);
    let text = '';
    if (this.hmCanvas.tabInfo.key === 'polyline' && this.data.type === 'door') text = this.hmCanvas.genNumber(this.data);
    else if (this.data.type !== 'door') text = this.data.title;
    text = text ? String(text) : '';
    if (!text) return;
    let fontSize = 6 * (this.hmCanvas.project.scale / 100);
    this.fabricText = new fabric.Text(text,
      {
        type: 'text',
        fontSize,
        fontFamily: 'ABCFavoritPro',
        // fontFamily: 'Arial',
        fontWeight: 400,
        fill: '#ffffff',
        backgroundColor: color,
        splitByGrapheme: false,
        hasControls: false,
        selectID: this.hmCanvas.tabInfo.key === 'polyline' ? null : this.data._id,
        lockMovementX: true,
        selectable: this.hmCanvas.tabInfo.key === 'polyline' ? false : true,
        lockMovementY: true,
        top: this.fabricElement.top - fontSize / 2 - 1,
        left: this.fabricElement.left + fontSize,
      });
    this.hmCanvas.fabricCanvas.add(this.fabricText);
  }

  remove() {
    super.remove();
    this.hmCanvas.fabricCanvas.remove(this.fabricText);
    this.hmCanvas.updatePointNumbers();
  }

  onMove() {
    this.renderTextBox();
  }

  moveTo(coords) {
    this.fabricElement.set({
      top: coords.y,
      left: coords.x,
    })
    this.data.pointCanvas = coords;
    this.update();
    return this;
  }

  updatePointDependencies() {
    let data = this._getData();
    let zones = geometryFunctions.checkPolygons(data, this.hmCanvas.plan.elements.filter(el => el.type === 'polygon'));
    this.update({zones}, 'updatePointDependencies');
    this.hmCanvas.options.onElementsChanged();
    this.hmCanvas.updatePointNumbers();
  }

  static create(hmCanvas, event = {}, point = {}) {
    let type = hmCanvas.tabInfo.key;
    let lastElement = hmCanvas._getLastAddedElementByType(type);
    if (!event.absolutePointer) event.absolutePointer = point;
    if (lastElement && !lastElement.data.title && hmCanvas.copyData?.type !== type) {
      lastElement.moveTo(event.absolutePointer);
      lastElement.updatePointDependencies();
      lastElement.select();
      // hmCanvas.options.onElementsChanged(hmCanvas.creatingElement);
    } else {
      let data = {};
      if (hmCanvas.copyData && hmCanvas.copyData.type === type) {
        data = globalMixins.clone(hmCanvas.copyData);
        delete data.point;
        data.pointCanvas = point;
      } else if (event.e && event.e.shiftKey && (event.e.metaKey || event.e.ctrlKey) && lastElement) {
        data = globalMixins.clone(lastElement.data);
        delete data.point;
        delete data.pointCanvas;
      }
      if (!data.pointCanvas) data.pointCanvas = {x: event.absolutePointer.x, y: event.absolutePointer.y};
      data.type = type;
      delete data.number;
      delete data.individualNumber;
      delete data.id;
      delete data._id;
      hmCanvas.creatingElement = (new Point(hmCanvas, data))
        .render(true).update({}, 'createdElement');
      hmCanvas.elements.unshift(hmCanvas.creatingElement);
      hmCanvas.options.onElementCreated(hmCanvas.creatingElement);
      hmCanvas.creatingElement.updatePointDependencies();
    }
  }
}

export default Point;
